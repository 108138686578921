import React, { Component } from 'react';
import FormularioPadrao from './FormularioPadrao';
import LogController from '../controllers/LogController';
import { Col, Row, Form, FormGroup } from 'react-bootstrap';
import { dateTimeToString } from '../utils/Functions';
import Label from '../components/Label';
import TextInput from '../components/TextInput';
import TextArea from '../components/TextArea';

export default class LogView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.controller = new LogController(this);
    }
    
   renderizarFormulario = () => {
      return (
         <React.Fragment>
            <Row>
               <Col sm={6} md={2} lg={2}>
                  <FormGroup>
                     <Label>{'C�digo'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{'ID da Requisi��o'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.groupId} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{'Data'}</Label>
                     <TextInput readOnly defaultValue={dateTimeToString(this.state.itemSelecionado.date)} />
                  </FormGroup>
               </Col>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{'Usu�rio'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.userId} />
                  </FormGroup>
               </Col>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{'Categoria'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.subCategory.description} />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{'IP'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.clientAddress} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{'A��o'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.action} />
                  </FormGroup>
               </Col>              
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{'M�todo HTTP'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.httpMethod} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{'Resposta HTTP'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.httpResponseCode} />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{'Mensagem'}</Label>
                     <TextArea
                        style={{ fontSize: 11 }}
                        readOnly
                        defaultValue={this.state.itemSelecionado.message}
                        rows={12}
                     />
                  </FormGroup>
               </Col>
            </Row>
         </React.Fragment>
      );
   };

    render() {
        return (
            <FormularioPadrao
                titulo={'Log'}              
                url={'/log'}
                api={this.props.api}
                lang={this.props.lang}
                ordenacaoPadrao={'date desc'}
            permissoes={[9001, null, null, null]}
                getFiltro={this.controller.getFiltro}
                getTitulosDaTabela={this.controller.getTitulosDaTabela}
                getDadosDaTabela={this.controller.getDadosDaTabela}
                renderizarFormulario={this.renderizarFormulario}
                getObjetoDeDados={this.controller.getObjetoDeDados}
                antesDeInserir={this.controller.antesDeInserir}
                antesDeEditar={this.controller.antesDeEditar}
                antesDeSalvar={this.controller.antesDeSalvar}
                antesDeExcluir={this.controller.antesDeExcluir}
            esconderFiltro={true}
                getFormState={() => {
                    return this.state;
                }}
                setFormState={(state, callback) => {
                    return new Promise((resolve) => {
                        this.setState(state, () => {
                            resolve();
                            if (callback) {
                                callback();
                            }
                        });
                    });
                }}
                select={this.props.select}
                itemVazio={{}}
            />
        );
    }
}
