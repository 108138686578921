import React from 'react';
import NumberFormat from 'react-number-format';
import '../contents/css/telefone-celular-input.css';

export default class TelefoneCelularInput extends React.Component {
    render() {
        return (
            <NumberFormat
                className={'form-control telefone-celular-input' + (this.props.className ? ' ' + this.props.className : '')}
                format='(##)#####-####'
                placeholder='(00)00000-0000'
                defaultValue={this.props.defaultValue}
                onValueChange={(value) => {
                    if (this.props.onChange) {
                        this.props.onChange(value.formattedValue);
                    }
                }}
                style={this.props.style}
                name={this.props.name}
                id={this.props.id}
                key={this.props.key} 
                ref={this.props.ref}
            />
        );
    }
}
