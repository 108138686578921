import { BaseController } from './BaseController';

export default class PaisController extends BaseController {
    constructor(props) {
        super(props);
        this.name = 'CidadeController';
    }

    getTitulosDaTabela = () => {
        return [
            { titulo: this.lang.telaDeCadastroDePais.codigo, orderby: 'Codigo', className: 'codigo' },            
            { titulo: this.lang.telaDeCadastroDePais.nome, width: '100%', orderby: "Nome" },
        ];
    };

    getDadosDaTabela = (item) => {
        return [item.codigo, item.nome];
    };

    getObjetoDeDados = () => {        
    };
}
