import React, { Component } from 'react'; 

export default class HomeTecnometricsView extends Component {
	render() {
		return (
			<React.Fragment>
				 
			</React.Fragment>
		);
	}
}
