import { dateTimeToString, emptyStrIfNull, numberToString } from '../utils/Functions';
import { BaseController } from './BaseController';

export default class PackageController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'PackageController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: 'Data da leitura', width: '20%', orderby: 'dataDaLeitura' },
         { titulo: 'Gateway', width: '20%', orderby: 'gateway' },
         { titulo: 'TAG', width: '20%', orderby: 'tag', className: '' },
         { titulo: 'Distância', width: '10%', orderby: 'distancia', className: 'right' },
         { titulo: 'Bateria', width: '10%', orderby: 'bateria', className: 'right' },
         { titulo: 'Temperatura', width: '10%', orderby: 'temperatura', className: 'right' },
         { titulo: 'Umidade', width: '10%', orderby: 'umidade', className: 'right' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         <span style={{ wordBreak: 'break-all' }}>{dateTimeToString(item.dataDaLeitura)}</span>,
         item.gateway,
         item.mac,
         numberToString(item.distancia) + 'm',
         item.bateria + 'v',
         emptyStrIfNull(numberToString(item.temperatura)) + (item.temperatura != null ? 'ºC' : ''),
         emptyStrIfNull(numberToString(item.umidade)) + (item.umidade != null ? '%' : ''),
      ];
   };

   getObjetoDeDados = () => {};
}
