import React from 'react';
import '../contents/css/panel.css';

export default class Panel extends React.Component {
    render() {
        let contentOnCenterStyle = this.props.contentOnCenter
            ? {
                  display: 'flex',
                  justifyContent: 'center',
              }
            : {};
        return (
            <div
                style={{ ...contentOnCenterStyle, height: this.props.height, width: this.props.width }}
                className='panel'
            >
                {this.props.children}
            </div>
        );
    }
}
