import { showError, showConfirm } from '../../components/Messages';
import { updateState, buildQueryString, rsaEncrypt, validarTelefone } from '../../utils/Functions';
import { BaseController } from '../BaseController';
import btoa from 'btoa';

export default class FuncionarioTecnometricsController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'FuncionarioTecnometricsController';
      this.props = props.props;
   }

   antesDeInserir = () => {
      return new Promise((resolve) => {
         updateState(this.view, (state) => {
            state.cpfParaNaoConsultar = null;
            resolve();
         });
      });
   };

   antesDeEditar = (formularioPadrao, funcionarioTecnometrics) => {
      return new Promise((resolve) => {
         updateState(this.view, (state) => {
            state.cpfParaNaoConsultar = funcionarioTecnometrics.cpf;
            resolve();
         });
      });
   };

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.telaDeCadastroDeFuncionarioTecnometrics.codigo, orderby: 'id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeFuncionarioTecnometrics.nome, width: '40%', orderby: 'nomeCompleto' },
         { titulo: this.lang.telaDeCadastroDeFuncionarioTecnometrics.apelido, width: '30%', orderby: 'apelido' },
         { titulo: this.lang.telaDeCadastroDeFuncionarioTecnometrics.email, width: '30%', orderby: 'email' },
      ];
   };

   getDadosDaTabela(item) {
      return [item.id, item.nomeCompleto, item.apelido, item.email];
   }

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.nomeCompleto) {
            showError(this.lang.telaDeCadastroDeFuncionarioTecnometrics.mensagens.informeONomeCompleto);
            reject();
            return;
         }

         if (!item.cpf) {
            showError(this.lang.telaDeCadastroDeFuncionarioTecnometrics.mensagens.informeOCpf);
            reject();
            return;
         }

         if (!item.sexo) {
            showError(this.lang.telaDeCadastroDeFuncionarioTecnometrics.mensagens.informeOSexo);
            reject();
            return;
         }

         if (item.sexo.id !== 1 && item.sexo.id !== 2) {
            showError(this.lang.telaDeCadastroDeFuncionarioTecnometrics.mensagens.informeOSexo);
            reject();
            return;
         }

         if (!item.perfis) {
            showError(this.lang.telaDeCadastroDeFuncionarioTecnometrics.mensagens.informePeloMenosUmPerfil);
            reject();
            return;
         }

         this.api
            .get('/publickey')
            .then((publicKey) => {
               let senhaCriptografada = null;
               let novaSenha = null;
               if (item.senha) {
                  let blocks = item.senha.match(new RegExp('.{1,' + 64 + '}', 'g'));
                  for (let i = 0; i < blocks.length; i++) {
                     let buffer = rsaEncrypt(blocks[i], publicKey);
                     senhaCriptografada = senhaCriptografada ? Buffer.concat([senhaCriptografada, buffer]) : buffer;
                  }
                  novaSenha = btoa(senhaCriptografada);
               }

               var input = {
                  nomeCompleto: item.nomeCompleto,
                  email: item.email,
                  sexo: item.sexo ? item.sexo.id : 0,
                  cpf: item.cpf,
                  documentoDeIdentidade: item.documentoDeIdentidade,
                  orgaoExpedidorDoDocumentoDeIdentidade: item.orgaoExpedidorDoDocumentoDeIdentidade,
                  dataDeNascimento: item.dataDeNascimento,
                  dataDeCadastro: item.dataDeCadastro ? item.dataDeCadastro : new Date(),
                  foto: item.foto && item.foto.id ? item.foto : null,
                  telefones: item.telefones,
                  enderecos: item.enderecos,
                  cargos: item.cargos,
                  redesSociais: item.redesSociais,
                  tipoDePessoa: item.tipoDePessoa ? item.tipoDePessoa.id : 0,
                  situacao: item.situacao ? item.situacao.id : 0,
                  apelido: item.apelido,
                  senhaAlterada: item.senhaAlterada,
                  novaSenha: novaSenha,
                  nomeDeUsuario: item.nomeDeUsuario,
                  perfis: item.perfis,
                  enviarNovaSenhaPorEmail: item.enviarNovaSenhaPorEmail,
               };

               if (state.alterando) {
                  input.id = parseInt(item.id);
                  input.nomeDeUsuario = null;
               }
               resolve(input);
            })
            .catch((e) => {
               console.error(e);
               reject();
            });
      });
   };

   consultarCep = () => {
      if (
         this.view.state.enderecoSelecionado.endereco.cep &&
         this.view.state.enderecoSelecionado.endereco.cep.length === 9 &&
         this.view.state.enderecoSelecionado.endereco.cep !== this.view.state.cepParaNaoConsultar
      ) {
         this.props.consultaDeCep.get(this.view.state.enderecoSelecionado.endereco.cep).then((resultViaCep) => {
            if (resultViaCep && resultViaCep.localidade && resultViaCep.uf) {
               let url =
                  '/cidade' +
                  buildQueryString(null, null, null, {
                     uf: resultViaCep.uf.toUpperCase(),
                     localidade: resultViaCep.localidade.toUpperCase(),
                  });
               this.props.api
                  .getAll(url, false)
                  .then((result) => {
                     if (result.count === 1) {
                        let enderecoEmEdicao = { endereco: {} };
                        enderecoEmEdicao.cep = this.view.state.enderecoSelecionado.endereco.cep;
                        enderecoEmEdicao.logradouro = resultViaCep.logradouro
                           ? resultViaCep.logradouro.toUpperCase()
                           : null;
                        enderecoEmEdicao.bairro = resultViaCep.bairro ? resultViaCep.bairro.toUpperCase() : null;
                        enderecoEmEdicao.cidade = result.items[0];

                        updateState(this.view, (state) => {
                           state.enderecoSelecionado.endereco.logradouro = enderecoEmEdicao.logradouro;
                           state.enderecoSelecionado.endereco.bairro = enderecoEmEdicao.bairro;
                           state.enderecoSelecionado.endereco.cidade = enderecoEmEdicao.cidade;
                           state.mostrarEndereco = false;
                           state.cepParaNaoConsultar = this.view.state.enderecoSelecionado.endereco.cep;
                        }).then(() => {
                           updateState(this.view, (state) => {
                              state.mostrarEndereco = true;
                           });
                        });
                     }
                  })
                  .catch(() => {});
            }
         });
      }
   };

   verificarSeCpfJaExiste = () => {
      let item = this.view.state.itemSelecionado;
      if (item && item.cpf && item.cpf.length === 14 && item.cpf !== this.view.state.cpfParaNaoConsultar) {
         this.props.api
            .getAll(
               this.view.state.url +
                  buildQueryString(null, null, null, {
                     cpf: this.view.state.itemSelecionado.cpf,
                  })
            )
            .then((result) => {
               if (result && result.count > 0) {
                  let empresa = result.items[0];
                  let mensagem = this.lang.telaDeCadastroDeFuncionarioTecnometrics.mensagens.FuncionarioTecnometricsJaCadastrado;
                  showConfirm(
                     mensagem,
                     () => {
                        updateState(this.view, (state) => {
                           state.incluindo = false;
                           state.alterando = false;
                        }).then(() => this.view.editar(empresa));
                     },
                     () => {
                        updateState(this.view, (state) => {
                           state.cpfParaNaoConsultar = item.cpf;
                        });
                     },
                     this.lang.sim,
                     this.lang.nao
                  );
               } else {
                  updateState(this.view, (state) => {
                     state.cpfParaNaoConsultar = item.cpf;
                  });
               }
            });
      }
   };

   inserirTelefone = () => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.telefones) {
               state.itemSelecionado.telefones = [];
            }
            state.itemSelecionado.telefones.push({               
               pais: this.view.state.telefoneSelecionado.pais,
               ddd: this.view.state.telefoneSelecionado.ddd,
               numero: this.view.state.telefoneSelecionado.numero,
               temWhatsApp: this.view.state.telefoneSelecionadotemWhatsApp ? true : false,
            });
         }).then(() => resolve());
      });
   };

   alterarTelefone = (index) => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }
         updateState(this, (state) => {
            state.itemSelecionado.telefones[index].pais = this.state.telefoneSelecionado.pais;
            state.itemSelecionado.telefones[index].ddd = this.state.telefoneSelecionado.ddd;
            state.itemSelecionado.telefones[index].numero = this.state.telefoneSelecionado.numero;
            state.itemSelecionado.telefones[index].temWhatsApp = this.state.telefoneSelecionado.temWhatsApp;
         }).then(() => resolve());
      });
   };

   inserirPerfil = () => {
      return new Promise((resolve, reject) => {
         let perfilSelecionado = this.view.state.perfilSelecionado;

         if (!perfilSelecionado) {
            showError(this.lang.telaDeCadastroDeFuncionarioTecnometrics.mensagens.selecioneUmPerfilDeUsuario);
            reject();
            return;
         }

         if (!this.view.state.itemSelecionado.perfis) {
            this.view.state.itemSelecionado.perfis = [];
         }

         let duplicado = this.view.state.itemSelecionado.perfis.some((i) => i.perfil.id === perfilSelecionado.id);

         if (duplicado) {
            showError(this.lang.telaDeCadastroDeFuncionarioTecnometrics.mensagens.perfilJaCadastrado);
            reject();
            return;
         }

         updateState(this.view, (state) => {
            state.itemSelecionado.perfis.push({
               perfil: perfilSelecionado,
            });
            state.perfilSelecionado = null;
         }).then(resolve);
      });
   };
}
