import React, { Component } from 'react';
import { dateTimeToString } from '../../utils/Functions';
import ListaPadrao from '../ListaPadrao';

export default class HistoricoDeTemperaturaView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   getFiltro = () => {
      return new Promise((resolve, reject) => {
         resolve({
            monitorado: this.props.idDoEquipamento,
         });
      });
   };

   getTitulosDaTabela = () => {
      return [
         {},
         { titulo: 'Temperatura', width: '20%' },
         { titulo: 'Data', width: '20%' },
         { titulo: 'Duração', width: '60%' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [null, item.temperatura + 'º', dateTimeToString(item.dataInicial), item.duracao];
   };

   render() {
      return (
         <ListaPadrao
            titulo={'Histórico de Temperatura'}
            url={'/historicodetemperatura'}
            api={this.props.api}
            lang={this.props.lang}
            getFiltro={this.getFiltro}
            renderizarFiltros={this.renderizarFiltros}
            getTitulosDaTabela={this.getTitulosDaTabela}
            getDadosDaTabela={this.getDadosDaTabela}
            aoFechar={this.props.aoFechar}
            getFormState={() => this.state}
            estruturaPadrao={true}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
         />
      );
   }
}
