import { showInfo } from '../../components/Messages';
import './../../contents/css/login-logo.css';
import { BaseController } from './../BaseController';
import { showError } from '../../components/Messages';

export default class RecoverPasswordController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'RecoverPasswordController';
   }

   recuperarSenha = () => {
      var result = new Promise((resolve, reject) => {
         let state = this.view.state;
         var input = {
            nomeDeUsuario: state.nomeDeUsuario,
            codigoDeSeguranca: state.codigoDeSeguranca,
            novaSenha: state.novaSenha,
            confirmacaoDaSenha: state.confirmacaoDaSenha,
            identificacaoComplementar: state.identificacaoComplementar,
            tipoDeAcesso: this.view.props.tipoDeAcesso,
         };
         this.api
            .protectedPost('/login/resetpassword', input)
            .then((result) => {
               if (result) {
                  showInfo(this.lang.telaDeRecuperacaoDeSenha.senhaAlteradaComSucesso).then(
                     () =>
                        (window.location =
                           './#' + (this.view.props.tipoDeAcesso === 'EMPRESA' ? '/' : '/adm'))
                  );
               }
               resolve();
            })
            .catch(reject);
      });
      return result;
   };

   enviarCodigoDeSeguranca = () => {
      var result = new Promise((resolve, reject) => {
         let tipoDeAcesso = this.view.props.tipoDeAcesso;
         let identificacaoComplementar = this.view.state.identificacaoComplementar;
         let nomeDeUsuario = this.view.state.nomeDeUsuario;

         if (tipoDeAcesso === 'EMPRESA' && !identificacaoComplementar) {
            showError(this.lang.telaDeLogin.mensagens.informeAIdentificacaoDaEmpresa);
            reject();
            return;
         }

         if (!nomeDeUsuario) {
            showError(this.lang.telaDeLogin.mensagens.informeONomeDeUsuario);
            reject();
            return;
         }

         let input = {
            nomeDeUsuario: nomeDeUsuario,
            identificacaoComplementar: identificacaoComplementar,
            tipoDeAcesso: tipoDeAcesso,
            recaptcha: this.view.state.recaptcha
         };
         this.api
            .post('/login/recoverpassword', input)
            .then(() => {
               this.view.setState({ codigoDeSegurancaEnviado: true });
               showInfo(this.lang.telaDeRecuperacaoDeSenha.codigoEnviadoParaoEmail);
               resolve();
            })
            .catch(reject);
      });
      return result;
   };
}
