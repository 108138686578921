import btoa from 'btoa'; 
import { 
   updateState,
   rsaEncrypt,
   validarTelefone,
} from '../../utils/Functions';
import { BaseController } from '../BaseController';

export default class DadosDeUsuarioDeEmpresaController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'DadosDeUsuarioDeEmpresaController';
      this.props = props.props;
   }

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;          

         this.api
            .get('/publickey')
            .then((publicKey) => {
               let senhaCriptografada = null;
               let novaSenha = null;
               if (item.senha) {
                  let blocks = item.senha.match(new RegExp('.{1,' + 64 + '}', 'g'));
                  for (let i = 0; i < blocks.length; i++) {
                     let buffer = rsaEncrypt(blocks[i], publicKey);
                     senhaCriptografada = senhaCriptografada ? Buffer.concat([senhaCriptografada, buffer]) : buffer;
                  }
                  novaSenha = btoa(senhaCriptografada);
               }

               var input = {
                  novaSenha: novaSenha,
                  senhaAlterada: item.senhaAlterada,
                  telefones: item.telefones,                  
               };

               if (state.alterando) {
                  input.id = parseInt(item.id);
               }

               resolve(input);
            })
            .catch(reject);
      });
   };   

   inserirTelefone = () => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.telefones) {
               state.itemSelecionado.telefones = [];
            }
            state.itemSelecionado.telefones.push({
               pais: this.view.state.telefoneSelecionado.pais,
               ddd: this.view.state.telefoneSelecionado.ddd,
               numero: this.view.state.telefoneSelecionado.numero,
               temWhatsApp: this.view.state.telefoneSelecionado.temWhatsApp ? true : false,
            });
         }).then(() => resolve());
      });
   };

   alterarTelefone = (index) => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }
         updateState(this.view, (state) => {
            state.itemSelecionado.telefones[index].pais = this.view.state.telefoneSelecionado.pais;
            state.itemSelecionado.telefones[index].ddd = this.view.state.telefoneSelecionado.ddd;
            state.itemSelecionado.telefones[index].numero = this.view.state.telefoneSelecionado.numero;
            state.itemSelecionado.telefones[index].temWhatsApp = this.view.state.telefoneSelecionado.temWhatsApp;
         }).then(() => resolve());
      });
   };   

}
