import React from 'react';

export default class Line extends React.Component {
   render() {
      return (
         <React.Fragment>
            <div
               style={{
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: this.props.marginTop ? this.props.marginTop : 0,
                  marginBottom: this.props.marginBottom ? this.props.marginBottom : 0,
                  backgroundColor: this.props.color ? this.props.color : '#ced4da',
                  height: this.props.height ? this.props.height : 1,
                  width: this.props.width ? this.props.width : '100%',
                  border: 0,
               }}
            />
         </React.Fragment>
      );
   }
}
