import { showError } from '../../components/Messages';
import cryptoJs from 'crypto-js';
import btoa from 'btoa';
import { rsaEncrypt } from '../../utils/Functions';

export default class LoginController {
   constructor(view) {
      this.view = view;
      this.api = view.api;
      this.lang = view.lang;
      this.sessionManager = view.sessionManager;
   }

   efetuarLogin = () => {
      var result = new Promise((resolve, reject) => {
         let nomeDeUsuario = this.view.state.nomeDeUsuario;
         let senha = this.view.state.senha;
         let tipoDeAcesso = this.view.props.tipoDeAcesso;
         let identificacaoComplementar = this.view.state.identificacaoComplementar;

         if (tipoDeAcesso === 'EMPRESA' && !identificacaoComplementar) {
            showError(this.lang.telaDeLogin.mensagens.informeAIdentificacaoDaEmpresa);
            reject();
            return;
         }

         if (!nomeDeUsuario) {
            showError(this.lang.telaDeLogin.mensagens.informeONomeDeUsuario);
            reject();
            return;
         }

         if (!senha) {
            showError(this.lang.telaDeLogin.mensagens.informeASenha);
            reject();
            return;
         }

         let input = {
            nomeDeUsuario: nomeDeUsuario,
            senha: senha,
            tipoDeAcesso: this.view.props.tipoDeAcesso,
            identificacaoComplementar: identificacaoComplementar,
            origem: 'WEB',
         };

         this.api
            .get('/publickey')
            .then((publicKey) => {
               let doLogin = () => {};

               doLogin = () => {
                  let key = cryptoJs.enc.Base64.stringify(cryptoJs.lib.WordArray.random(32));
                  let iv = cryptoJs.enc.Base64.stringify(cryptoJs.lib.WordArray.random(16));

                  let symmetricKey = {
                     key: key,
                     iv: iv,
                  };

                  input.symmetricKey = symmetricKey;

                  let inputString = JSON.stringify(input);

                  let blocks = inputString.match(new RegExp('.{1,' + 16 + '}', 'g'));
                  let encryptedInput = [];
                  for (let i = 0; i < blocks.length; i++) {
                     encryptedInput.push(btoa(rsaEncrypt(blocks[i], publicKey)));
                  }

                  let tokenInput = {
                     data: encryptedInput,
                     recaptcha: this.view.state.recaptcha,
                  };

                  this.api
                     .post('/login/token', tokenInput, true, false)
                     .then((result) => {
                        let decryptedToken = cryptoJs.AES.decrypt(result.token, cryptoJs.enc.Base64.parse(key), {
                           iv: cryptoJs.enc.Base64.parse(iv),
                        }).toString(cryptoJs.enc.Utf8);
                        result.token = decryptedToken;
                        if (tipoDeAcesso === 'TECNOMETRICS-SUPERVISOR') {
                           this.view.setState({
                              selecionarEmpresaParaAcessoSupervisor: true,
                              empresas: result.identificacaoDasEmpresas,
                              loginResult: result,
                           });
                        } else {
                           this.sessionManager.setLogin(result);
                           window.location = './';
                        }
                        resolve();
                     })
                     .catch((e) => {
                        if (e.toString().includes('Erro ao descriptografar os dados')) {
                           doLogin();
                        } else {
                           showError(e.toString());
                        }
                     });
               };
               doLogin();
            })
            .catch(reject);
      });
      return result;
   };

   definirEmpresaParaAcessoSupervisor = (empresa) => {
      let loginResult = this.view.state.loginResult;
      loginResult.empresa = empresa;
      loginResult.representado = empresa;
      loginResult.impersonatedBy = empresa.idDeUsuario;
      loginResult.tipoDeContrato = empresa.tipoDeContrato ? empresa.tipoDeContrato.id : null;
      this.sessionManager.setLogin(loginResult);
      window.location = './';
   };
}
