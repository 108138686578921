import React from 'react';
import { LayoutParams } from '../config/LayoutParams';
import './../contents/css/login-logo.css';

export const LogoLogin = () => {
   return (
      <div className='div-login-logo' style={{color: 'white', zIndex: 1, textAlign: 'center' }}>
         <img alt='' src={LayoutParams.imgLogoLogin} className='login-logo' />
      </div>
   );
};
