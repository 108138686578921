import React, { Component, Fragment } from 'react';
import DadosDeUsuarioDeEmpresaView from './Empresa/DadosDeUsuarioDeEmpresaView';
import DadosDaEmpresaView from './Empresa/DadosDaEmpresaView'; 

export default class AccountView extends Component {  

   render() {
      let login = this.props.sessionManager.getLogin();

      return (
         <Fragment>
            {login.tipoDeAcesso === 'TECNOMETRICS' && <div />}

            {login.tipoDeAcesso === 'EMPRESA' && (
               <Fragment>
                  {login.isMasterDaEmpresa && (
                     <DadosDaEmpresaView
                        api={this.props.api}
                        lang={this.props.lang}
                        sessionManager={this.props.sessionManager}
                        id={login.idDaEmpresa}
                     />
                  )}

                  {!login.isMasterDaEmpresa && (
                     <DadosDeUsuarioDeEmpresaView
                        api={this.props.api}
                        lang={this.props.lang}
                        sessionManager={this.props.sessionManager}
                        id={login.idDoUsuario}
                     />
                  )}
               </Fragment>
            )}              
         </Fragment>
      );
   }
}
