import React from 'react';
import NumberFormat from 'react-number-format';
import '../contents/css/cnpj-input.css';

export default class CnpjInput extends React.Component {
    render() {
        return (
            <NumberFormat
                className={'form-control cnpj-input' + (this.props.className ? ' ' + this.props.className : '')}
                format='##.###.###/####-##'
                defaultValue={this.props.defaultValue}
                onValueChange={this.props.onChange}
                onBlur={this.props.onBlur}
                style={this.props.style}
                name={this.props.name}
                id={this.props.id}
                key={this.props.key}
                ref={this.props.ref}
                readOnly={this.props.readOnly}
            />
        );
    }
}
