import React from 'react';
import { FormControl } from 'react-bootstrap';
import '../contents/css/text-input.css';

export default class TextArea extends React.Component {
    render() {
        return (
            <FormControl
                className={'text-input' + (this.props.className ? ' ' + this.props.className : '')}                  
                as="textarea"              
                rows={this.props.rows ? this.props.rows : 3}
                style={this.props.style}
                name={this.props.name}
                id={this.props.id}
                key={this.props.key}
                ref={this.props.ref}
                onChange={this.props.onChange}
                defaultValue={this.props.defaultValue}
                onInput={(e) => {
                    if (this.props.upperCase) {
                        e.target.value = ('' + e.target.value).toUpperCase();
                    }
                }}
                readOnly={this.props.readOnly}
            />
        );
    }
}
