import React from 'react';
import TimeField from 'react-simple-timefield';
import { FormControl } from 'react-bootstrap';
import '../contents/css/time-input.css';

export default class TimeInput extends React.Component {
    render() {
        return (
            <TimeField
                className={'form-control time-input' + (this.props.className ? (' ' + this.props.className) : '')}
                value={this.props.defaultValue ? this.props.defaultValue : this.props.showSeconds ? '00:00:00' : '00:00'}
                showSeconds={this.props.showSeconds}
                readOnly={this.props.readOnly}
                onChange={(e, time) => {
                    if (this.props.onChange) {
                        this.props.onChange(time);
                    }
                }}
                input={
                    <FormControl
                        type='text'
                        style={this.props.style}
                        name={this.props.name}
                        id={this.props.id}
						key={this.props.key}
						ref={this.props.ref}
                    />
                }
            />
        );
    }
}
