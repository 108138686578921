import React, { Component, Fragment } from 'react';
import IconButton from './IconButton';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import '../contents/css/sub-cadastro.css';
import Button from './Button';
import Line from './Line';
import { showConfirm } from '../components/Messages';
import { Filler } from './Filler';
import { Modal } from 'react-bootstrap';
import { LayoutParams } from './../config/LayoutParams';

export default class SubCadastro extends Component {
   constructor(props) {
      super(props);
      this.state = {
         inserindo: false,
         alterando: false,
         exibirTitulos: props.exibirTitulos === false ? false : true,
         exibirRodape: props.exibirRodape === true ? true : false,
         indiceEmEdicao: null,
      };
      this.lang = this.props.lang;
   }

   renderizarAcoes = (index) => {
      return this.props.alterar ||
         (this.props.excluir &&
            ((this.props.podeExcluir && this.props.podeExcluir(index)) || !this.props.podeExcluir)) ||
         this.props.acoes ? (
         <td key='acoes' className='acoes'>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
               {this.props.alterar &&
                  (!this.props.gradeSempreVisivel ||
                     (this.props.gradeSempreVisivel && !(this.state.inserindo || this.state.alterando))) && (
                     <div style={{ display: 'table-cell', margin: 'auto' }}>
                        <BotaoAlterarItemDeSubCadastro
                           onClick={() => this.selecionarParaAlteracao(index)}
                           title={this.lang.formularioPadrao.alterar}
                           lang={this.lang}
                        />
                     </div>
                  )}
               {this.props.excluir &&
                  ((this.props.podeExcluir && this.props.podeExcluir(index)) || !this.props.podeExcluir) && (
                     <div style={{ display: 'table-cell', margin: 'auto' }}>
                        <BotaoExcluirItemDeSubCadastro
                           onClick={() => {
                              this.props.excluir(index);
                           }}
                           title={this.lang.formularioPadrao.excluir}
                           lang={this.lang}
                        />
                     </div>
                  )}
               {this.props.acoes && this.props.acoes(this, index)}
            </div>
         </td>
      ) : null;
   };

   novo = async () => {
      if (this.props.formularioPadrao) {
         this.setState({
            inserindo: true,
            alterando: false,
            indiceEmEdicao: null,
         });
      } else if (this.props.novo) {
         await this.props.novo();
         this.setState({
            inserindo: true,
            alterando: false,
            indiceEmEdicao: null,
         });
      } else {
         this.setState({
            inserindo: true,
            alterando: false,
            indiceEmEdicao: null,
         });
      }
   };

   cancelar = async () => {
      if (this.props.cancelar) {
         this.props.cancelar();
         this.setState({
            inserindo: false,
            alterando: false,
            indiceEmEdicao: null,
         });
      } else {
         this.setState({
            inserindo: false,
            alterando: false,
            indiceEmEdicao: null,
         });
      }
   };

   selecionarParaAlteracao = async (index) => {
      if (this.props.selecionarParaAlteracao) {
         await this.props.selecionarParaAlteracao(index);
         this.setState({ alterando: true, inserindo: false, indiceEmEdicao: index });
      }
   };

   salvar = () => {
      return new Promise((resolve, reject) => {
         this.state.inserindo
            ? this.props
                 .inserir()
                 .then(() => {
                    this.setState(
                       {
                          inserindo: false,
                          alterando: false,
                       },
                       resolve
                    );
                    if (this.props.aposSalvar) {
                       this.props.aposSalvar(this, true, false);
                    }
                 })
                 .catch((e) => {
                    console.error(e);
                    if (reject) {
                       reject(e);
                    }
                 })
            : this.props
                 .alterar(this.state.indiceEmEdicao)
                 .then(() => {
                    this.setState(
                       {
                          inserindo: false,
                          alterando: false,
                       },
                       resolve
                    );
                    if (this.props.aposSalvar) {
                       this.props.aposSalvar(this, false, true);
                    }
                 })
                 .catch((e) => {
                    console.error(e);
                    if (reject) {
                       reject(e);
                    }
                 });
      });
   };

   aoCancelar() {
      this.setState({
         inserindo: false,
      });
   }

   aoSelecionar = (item) => {
      if (this.props.aoSelecionar) {
         this.props.aoSelecionar(item).then(() => {
            this.setState({
               inserindo: false,
               alterando: false,
            });
            if (this.props.aposSalvar) {
               this.props.aposSalvar(this, true, false);
            }
         });
      }
   };

   render() {
      const colunas = this.props.colunas && this.props.colunas();
      const tamanhos = colunas && colunas.map((i) => i.width);
      const classes = colunas && colunas.map((i) => i.className);
      return (
         <div className='sub-cadastro'>
            <table
               className={'table-hover ' + (this.props.itens && this.props.itens.length ? '' : 'empty-table')}
               style={this.state.inserindo || this.state.alterando ? this.props.formStyle : this.props.tableStyle}
            >
               <thead>
                  <tr>
                     <th colSpan={10} style={{ width: '100%' }}>
                        {((!this.state.inserindo && !this.state.alterando) ||
                           this.props.modal ||
                           this.props.formularioPadrao) && (
                           <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                              <div style={{ width: '100%', display: 'table-cell' }}>
                                 <div style={{ fontWeight: '600', paddingTop: 6 }}>
                                    <span>{this.props.titulo}</span>
                                 </div>
                              </div>
                              <div
                                 className='hide-when-readonly'
                                 style={{ width: 30, display: 'table-cell', textAlign: 'right' }}
                              >
                                 {this.props.podeInserir !== false &&
                                    (this.props.novo || this.props.formularioPadrao) && (
                                       <IconButton
                                          title={this.lang.formularioPadrao.novo}
                                          className='sub-cadastro-btn-novo'
                                          style={{
                                             fontSize: 30,
                                             paddingTop: 2,
                                             paddingRight: 5,
                                             color: LayoutParams.colors.corSecundaria,
                                          }}
                                          cursor='pointer'
                                          icon={faPlusCircle}
                                          onClick={this.novo}
                                       />
                                    )}
                              </div>
                           </div>
                        )}

                        {(this.state.inserindo || this.state.alterando) &&
                           this.props.formulario &&
                           !this.props.formularioPadrao &&
                           !this.props.modal && (
                              <Fragment>
                                 <Filler height={5} />
                                 <div style={{ fontWeight: 'normal', paddingLeft: 5, paddingRight: 5 }}>
                                    {this.props.formulario(this)}
                                 </div>
                                 <Line marginTop={5} marginBottom={6} />
                                 <div
                                    style={{
                                       display: 'flex',
                                       flexDirection: 'row',
                                       justifyContent: 'flex-end',
                                    }}
                                 >
                                    <div style={{ display: 'table-cell' }}>
                                       <Button
                                          text={
                                             this.props.tituloDoBotaoCancelar
                                                ? this.props.tituloDoBotaoCancelar
                                                : this.lang.formularioPadrao.cancelar
                                          }
                                          onClick={this.cancelar}
                                          style={{ fontSize: 16, height: 33 }}
                                       />
                                    </div>
                                    <div style={{ display: 'table-cell' }}>
                                       <Button
                                          text={this.state.alterando ? 'OK' : this.lang.formularioPadrao.inserir}
                                          style={{ fontSize: 16, height: 33 }}
                                          onClick={() => {
                                             new Promise((resolve, reject) => {
                                                this.state.inserindo
                                                   ? this.props
                                                        .inserir()
                                                        .then(() => {
                                                           this.setState(
                                                              {
                                                                 inserindo: false,
                                                                 alterando: false,
                                                              },
                                                              resolve
                                                           );
                                                           if (this.props.aposSalvar) {
                                                              this.props.aposSalvar(this, true, false);
                                                           }
                                                        })
                                                        .catch((e) => {
                                                           console.error(e);
                                                           if (reject) {
                                                              reject(e);
                                                           }
                                                        })
                                                   : this.props
                                                        .alterar(this.state.indiceEmEdicao)
                                                        .then(() => {
                                                           this.setState(
                                                              {
                                                                 inserindo: false,
                                                                 alterando: false,
                                                              },
                                                              resolve
                                                           );
                                                           if (this.props.aposSalvar) {
                                                              this.props.aposSalvar(this, false, true);
                                                           }
                                                        })
                                                        .catch((e) => {
                                                           console.error(e);
                                                           if (reject) {
                                                              reject(e);
                                                           }
                                                        });
                                             });
                                          }}
                                       />
                                    </div>
                                 </div>
                              </Fragment>
                           )}

                        {(this.state.inserindo || this.state.alterando) &&
                           this.props.formulario &&
                           !this.props.formularioPadrao &&
                           this.props.modal && (
                              <Modal
                                 show={this.state.inserindo || this.state.alterando}
                                 scrollable={true}
                                 onHide={() => {}}
                                 onKeyDown={(e) => {
                                    if (e.keyCode === 27) this.cancelar();
                                 }}
                                 size='lg'
                                 aria-labelledby='contained-modal-title-vcenter'
                                 centered
                                 stype={{ padding: 0 }}
                              >
                                 <div
                                    style={{
                                       backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
                                       color: LayoutParams.colors.corSecundaria,
                                       height: 42,
                                       fontSize: 24,
                                       padding: '3px 0 0 5px',
                                    }}
                                 >
                                    <span>{this.props.titulo}</span>
                                 </div>

                                 <Modal.Body
                                    style={{
                                       padding: '0 15px 15px 15px',
                                       fontSize: 13,
                                    }}
                                 >
                                    <div>
                                       <Filler height={5} />
                                       <div style={{ fontWeight: 'normal' }}>{this.props.formulario(this)}</div>
                                       <Line marginTop={5} marginBottom={6} />
                                       <div
                                          style={{
                                             display: 'flex',
                                             flexDirection: 'row',
                                             justifyContent: 'flex-end',
                                          }}
                                       >
                                          <div style={{ display: 'table-cell' }}>
                                             <Button
                                                text={
                                                   this.props.tituloDoBotaoCancelar
                                                      ? this.props.tituloDoBotaoCancelar
                                                      : this.lang.formularioPadrao.cancelar
                                                }
                                                onClick={this.cancelar}
                                                style={{ fontSize: 16, height: 33 }}
                                             />
                                          </div>
                                          <div style={{ display: 'table-cell' }}>
                                             <Button
                                                text={this.state.alterando ? 'OK' : this.lang.formularioPadrao.inserir}
                                                style={{ fontSize: 16, height: 33 }}
                                                onClick={this.salvar}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 </Modal.Body>
                              </Modal>
                           )}

                        {this.state.inserindo && this.props.formularioPadrao && (
                           <Modal
                              show={this.state.inserindo}
                              scrollable={true}
                              onHide={() => {}}
                              onKeyDown={(e) => {
                                 if (e.keyCode === 27) this.cancelar();
                              }}
                              size='lg'
                              dialogClassName='h-100'
                           >
                              <Modal.Body
                                 style={{
                                    overflow: 'hidden',
                                    display: 'flex',
                                    position: 'relative',
                                    fontSize: 13,
                                    padding: '0 0 0 0',
                                    maxHeight: '100%',
                                 }}
                              >
                                 {this.props.formularioPadrao(this)}
                              </Modal.Body>
                           </Modal>
                        )}
                     </th>
                  </tr>

                  {!this.state.inserindo &&
                     !this.state.alterando &&
                     (!this.props.itens || (this.props.itens && this.props.itens.length === 0)) && (
                        <div style={{ textAlign: 'center' }}>
                           <span>
                              {this.props.exibirTitulos
                                 ? this.props.lang.formularioPadrao.mensagens.nenhumRegistroEncontrado
                                 : null}
                           </span>
                        </div>
                     )}

                  {((!this.state.inserindo && !this.state.alterando) ||
                     this.props.modal ||
                     this.props.gradeSempreVisivel) &&
                     this.state.exibirTitulos &&
                     this.props.itens &&
                     this.props.itens.length > 0 &&
                     colunas && (
                        <tr>
                           <React.Fragment>
                              {colunas.map((item, index) => {
                                 return (
                                    <th key={index} className={item.className} style={{ width: item.width }}>
                                       {item.titulo}
                                    </th>
                                 );
                              })}
                           </React.Fragment>
                           <React.Fragment>
                              {(this.props.alterar || this.props.excluir || this.props.acoes) && (
                                 <th key='acoes' className='acoes'>
                                    {this.props.tituloDasAcoes
                                       ? this.props.tituloDasAcoes()
                                       : this.lang.formularioPadrao.acoes}
                                 </th>
                              )}
                           </React.Fragment>
                        </tr>
                     )}
               </thead>

               {((!this.state.inserindo && !this.state.alterando) ||
                  this.props.modal ||
                  this.props.formularioPadrao ||
                  this.props.gradeSempreVisivel) && (
                  <Fragment>
                     {!this.props.itens || this.props.itens.length === 0 ? (
                        <table className='tabela-formulario-padrao'>
                           <tbody>
                              <tr>
                                 <td style={{ width: '100%', textAlign: 'center', color: '#999' }}>
                                    <span>{this.props.lang.formularioPadrao.mensagens.nenhumRegistroEncontrado}</span>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     ) : (
                        <tbody>
                           {this.props.itens.map((item, rowIndex) => {
                              return !item ? null : (
                                 <tr
                                    key={rowIndex}
                                    className={this.props.select ? 'noselect' : null}
                                    style={{
                                       cursor: this.props.select ? 'pointer' : 'default',
                                       fontWeight: 'normal',
                                    }}
                                 >
                                    <React.Fragment>
                                       {this.props.linha(item).map((dado, campoIndex) => {
                                          return (
                                             <td
                                                key={campoIndex}
                                                className={classes && classes[campoIndex]}
                                                style={{ width: tamanhos && tamanhos[campoIndex] }}
                                             >
                                                {dado}
                                             </td>
                                          );
                                       })}
                                    </React.Fragment>
                                    <React.Fragment>{this.renderizarAcoes(rowIndex)}</React.Fragment>
                                 </tr>
                              );
                           })}
                        </tbody>
                     )}
                  </Fragment>
               )}
               {!this.state.inserindo &&
               !this.state.alterando &&
               this.state.exibirRodape &&
               this.props.itens &&
               this.props.itens.length > 0 ? (
                  <tfoot>{this.props.rodape()}</tfoot>
               ) : null}
            </table>
         </div>
      );
   }
}

class BotaoAlterarItemDeSubCadastro extends React.Component {
   render() {
      const lang = this.props.lang;
      return (
         <IconButton
            title={lang.formularioPadrao.alterar}
            style={{
               fontSize: 23,
               paddingTop: 2,
               marginLeft: 5,
               marginRight: 5,
               color: LayoutParams.colors.corSecundaria,
            }}
            cursor='pointer'
            className='sub-cadastro-btn-alterar'
            icon={faEdit}
            onClick={this.props.onClick}
         />
      );
   }
}

class BotaoExcluirItemDeSubCadastro extends React.Component {
   render() {
      const lang = this.props.lang;
      return (
         <IconButton
            title={lang.formularioPadrao.excluir}
            style={{
               fontSize: 23,
               paddingTop: 2,
               marginLeft: 5,
               marginRight: 5,
               color: LayoutParams.colors.corSecundaria,
            }}
            cursor='pointer'
            className='sub-cadastro-btn-alterar'
            icon={faTrashAlt}
            onClick={() => {
               if (this.props.onClick) {
                  showConfirm(this.props.lang.formularioPadrao.mensagens.desejaRealmenteExcluirEsteRegistro, () => {
                     this.props.onClick();
                  });
               }
            }}
         />
      );
   }
}
