import axios from 'axios';
import SessionManager from './../models/SessionManager';
import { getLang } from './../models/Lang';
import { ConnectionParams } from './../config/ConnectionParams';
import btoa from 'btoa';
import { rsaEncrypt } from './Functions';
import { showError } from '../components/Messages';

const urlBase = ConnectionParams.apiUrl;

export const DEBUG = false;

export default class Api {
   constructor() {
      this.cache = [];

      this.insertToCache = this.insertToCache.bind(this);
      this.getFromCache = this.getFromCache.bind(this);
      this.getAll = this.getAll.bind(this);
      this.startLoading = this.startLoading.bind(this);
      this.finishLoading = this.finishLoading.bind(this);
      this.loading = false;
   }

   insertToCache = (url, data) => {
      let indice = -1;
      this.cache.map((item, index) => {
         if (item.url === url) {
            indice = index;
         }
         return true;
      });

      if (indice === -1) {
         this.cache.push({
            url: url,
            data: data,
         });
      }
   };

   getFromCache = (url) => {
      let result = null;
      let indice = -1;
      this.cache.map((item, index) => {
         if (item.url === url) {
            indice = index;
         }
         return true;
      });

      if (indice >= 0) {
         result = this.cache[indice];
      }

      return result;
   };

   urlBase = () => urlBase;

   getOptions = (queryParams, responseType) => {
      let sessionManager = new SessionManager();
      let login = sessionManager.getLogin();
      let result = {
         responseType: responseType,
         headers: {
            Authorization: login ? login.token : null,
            UserId: login ? login.idDoUsuario : null,
            lang: login && login.idioma ? login.idioma : null,
            RepresentingId: login && login.impersonatedBy ? login.impersonatedBy : null,
         },
         params: queryParams,
      };
      return result;
   };

   startLoading = () => {
      this.loading = true;
      setTimeout(() => {
         if (this.loading) {
            document.getElementById('loading').className = 'loadingDiv';
         }
      }, 2000);
   };

   finishLoading = () => {
      this.loading = false;
      document.getElementById('loading').className = 'loadingDiv hide';
   };

   post = (url, data, useProgress = true, showErrors = true) => {
      return new Promise((resolve, reject) => {
         if (useProgress) {
            this.startLoading();
         }
         axios
            .post(urlBase + url, data, this.getOptions())
            .then((result) => {
               this.finishLoading();
               resolve(result.data);
            })
            .catch((e) => {
               this.finishLoading();
               if (showErrors) {
                  this.handleErrorMessage(e, reject);
               } else {
                  reject(this.getErrorMessage(e));
               }
            });
      });
   };

   put = (url, data, useProgress = true, showErrors = true) => {
      return new Promise((resolve, reject) => {
         if (useProgress) {
            this.startLoading();
         }
         axios
            .put(urlBase + url, data, this.getOptions())
            .then((result) => {
               this.finishLoading();
               resolve(result.data);
            })
            .catch((e) => {
               this.finishLoading();
               if (showErrors) {
                  this.handleErrorMessage(e, reject);
               } else {
                  reject(this.getErrorMessage(e));
               }
            });
      });
   };

   delete = (url) => {
      return new Promise((resolve, reject) => {
         this.startLoading();
         axios
            .delete(urlBase + url, this.getOptions())
            .then((result) => {
               this.finishLoading();
               resolve(result.data);
            })
            .catch((e) => {
               this.finishLoading();
               this.handleErrorMessage(e, reject);
            });
      });
   };

   get = (url, useCache = false, responseType = '', useProgress = true, showErrors = true) => {
      return new Promise((resolve, reject) => {
         let achouNoCache = false;
         let cachedResult = null;

         if (useCache) {
            cachedResult = this.getFromCache(url);
            achouNoCache = cachedResult ? true : false;
         }

         if (!achouNoCache) {
            if (useProgress) {
               this.startLoading();
            }
            axios
               .get(urlBase + url, this.getOptions(null, responseType))
               .then((result) => {
                  this.finishLoading();
                  if (useCache) {
                     this.insertToCache(url, result.data);
                  }
                  resolve(result.data.lenght > 0 ? result.data[0] : result.data);
               })
               .catch((e) => {
                  this.finishLoading();
                  if (showErrors) {
                     this.handleErrorMessage(e, reject);
                  } else {
                     reject(e);
                  }
               });
         } else {
            resolve(cachedResult.data);
         }
      });
   };

   getAll = (url, useCache = false) => {
      return new Promise((resolve, reject) => {
         let achouNoCache = false;
         let cachedResult = null;

         if (useCache) {
            cachedResult = this.getFromCache(url);
            achouNoCache = cachedResult ? true : false;
         }

         if (!achouNoCache) {
            this.startLoading();
            axios
               .get(urlBase + url, this.getOptions())
               .then((result) => {
                  this.finishLoading();
                  if (useCache) {
                     this.insertToCache(url, result.data);
                  }
                  resolve(result.data);
               })
               .catch((e) => {
                  this.finishLoading();
                  this.handleErrorMessage(e, reject);
               });
         } else {
            resolve(cachedResult.data);
         }
      });
   };

   query = (url, queryParams) => {
      return new Promise((resolve, reject) => {
         this.startLoading();
         axios
            .get(urlBase + url, this.getOptions(queryParams))
            .then((result) => {
               this.finishLoading();
               resolve(result.data);
            })
            .catch((e) => {
               this.finishLoading();
               this.handleErrorMessage(e, reject);
            });
      });
   };

   handleErrorMessage = (error, reject) => {
      let message = this.getErrorMessage(error);
      let details = this.getErrorDetails(error);
      let stack = this.getErrorStack(error);
      showError(message, details, stack);
      if (reject) {
         reject(message);
      }
   };

   getErrorMessage = (error) => {
      let mensagem = '';
      if (error.response && error.response.data && error.response.data.errorMessage) {
         mensagem = error.response.data.errorMessage;
      } else if (error.response && error.response.data && error.response.data.ExceptionMessage) {
         mensagem = error.response.request.response;
      } else if (error.response && error.response.request && error.response.request.response) {
         mensagem = error.response.request.response;
      } else if (error.response && error.response.statusText) {
         mensagem = error.response.statusText;
      } else {
         mensagem = error.message;
      }

      if (mensagem === 'Network Error') {
         mensagem = getLang('pt-BR').comunicacao.servidorIndisponivel;
      }
      return mensagem;
   };  

   getErrorDetails = (error) => {
      let mensagem = '';
      if (error.response && error.response.data && error.response.data.details) {
         mensagem = error.response.data.details;      
      }
      return mensagem;
   };

   getErrorStack = (error) => {
      let mensagem = '';
      if (error.response && error.response.data && error.response.data.stackTrace) {
         mensagem = error.response.data.stackTrace;
      }
      return mensagem;
   };

   protectedPost = (url, data) => {
      return new Promise((resolve, reject) => {
         this.get('/publickey').then((publicKey) => {
            let inputString = JSON.stringify(data);
            let blocks = inputString.match(new RegExp('.{1,' + 16 + '}', 'g'));
            let encryptedInput = [];
            for (let i = 0; i < blocks.length; i++) {
               encryptedInput.push(btoa(rsaEncrypt(blocks[i], publicKey)));
            }
            this.post(url, { data: encryptedInput }).then(resolve).catch(reject);
         });
      });
   };

   protectedPut = (url, data) => {
      return new Promise((resolve, reject) => {
         this.get('/publickey').then((publicKey) => {
            let inputString = JSON.stringify(data);
            let blocks = inputString.match(new RegExp('.{1,' + 16 + '}', 'g'));
            let encryptedInput = [];
            for (let i = 0; i < blocks.length; i++) {
               encryptedInput.push(btoa(rsaEncrypt(blocks[i], publicKey)));
            }
            this.put(url, { data: encryptedInput }).then(resolve).catch(reject);
         });
      });
   };
}
