import { ListGroup, Modal } from 'react-bootstrap';
import React from 'react';
import { IconeBandeiraBrasil } from '../components/IconeBandeiraBrasil';
import { IconeBandeiraUSA } from '../components/IconeBandeiraUSA';
import { IconeBandeiraEspanha } from '../components/IconeBandeiraEspanha';

export default class SelecionarIdiomaView extends React.Component {
   render() {
      return (
         <Modal
            show={this.props.selecionandoIdioma}
            scrollable={true}
            centered
            onHide={() => {}}
            onKeyDown={(e) => {
               if (e.keyCode === 27) {
                  if (this.props.onClose) {
                     this.props.onClose();
                  }
               }
            }}
         >
            <Modal.Body
               style={{
                  overflow: 'hidden',
                  display: 'block',
                  position: 'relative',
                  fontSize: 13,
                  padding: '0 0 0 0',
                  maxHeight: '100%',
               }}
            >
               <ListGroup>
                  <ListGroup.Item
                     href='#'
                     variant='light'
                     block
                     onClick={() => {
                        if (this.props.alterarIdioma) {
                           this.props.alterarIdioma('pt-BR');
                        }
                        if (this.props.onClose) {
                           this.props.onClose();
                        }
                     }}
                     style={{ display: 'flex', flexWrap: 'wrap', cursor: 'pointer' }}
                  >
                     <div style={{ width: 50, padding: '5px 0 0 5px' }}>
                        <IconeBandeiraBrasil />
                     </div>
                     <div style={{ paddingTop: 7 }}>Português do Brasil</div>
                  </ListGroup.Item>

                  <ListGroup.Item
                     href='#'
                     variant='light'
                     block
                     onClick={() => {
                        if (this.props.alterarIdioma) {
                           this.props.alterarIdioma('en-US');
                        }
                        if (this.props.onClose) {
                           this.props.onClose();
                        }
                     }}
                     style={{ display: 'flex', flexWrap: 'wrap', cursor: 'pointer' }}
                  >
                     <div style={{ width: 50, padding: '5px 0 0 5px' }}>
                        <IconeBandeiraUSA />
                     </div>
                     <div style={{ paddingTop: 7 }}>English</div>
                  </ListGroup.Item>

                  <ListGroup.Item
                     href='#'
                     variant='light'
                     block
                     onClick={() => {
                        if (this.props.alterarIdioma) {
                           this.props.alterarIdioma('es');
                        }
                        if (this.props.onClose) {
                           this.props.onClose();
                        }
                     }}
                     style={{ display: 'flex', flexWrap: 'wrap', cursor: 'pointer' }}
                  >
                     <div style={{ width: 50, padding: '5px 0 0 5px' }}>
                        <IconeBandeiraEspanha />
                     </div>
                     <div style={{ paddingTop: 7 }}>Español</div>
                  </ListGroup.Item>
               </ListGroup>
            </Modal.Body>
         </Modal>
      );
   }
}
