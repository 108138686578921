import ptBR from './../contents/lang/pt-BR.json';
import enUS from './../contents/lang/en-US.json';
import es from './../contents/lang/es.json';

export const getLang = (idioma = 'pt-BR') => {
   var result = {};
   if (idioma === 'pt-BR') {
      result = ptBR;      
   } else 
   if (idioma === 'en-US') {
      result = enUS;      
   } else 
   if (idioma === 'es') {
      result = es;      
   } else    
   {
      throw new Error('Idioma não configurado: ' + idioma);
   }
   return result;
};
