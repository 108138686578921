import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Select from '../../components/Select';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import CnpjInput from '../../components/CnpjInput';
import CepInput from '../../components/CepInput';
import DateInput from '../../components/DateInput';
import SubCadastro from '../../components/SubCadastro';
import Avatar from '../../components/Avatar';
import { updateState, buildQueryString, getEnderecoCompleto, prepareSearchable } from '../../utils/Functions';
import DadosDaEmpresaController from '../../controllers/Empresa/DadosDaEmpresaController';
import CheckBox from '../../components/CheckBox';
import { IconeWhatsApp } from '../../components/IconeWhatsApp';
import CidadeView from '../CidadeView';
import PasswordInput from '../../components/PasswordInput';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import PaisView from '../PaisView';

const url = '/dadosdaempresa';

export default class DadosDaEmpresaView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         foto: null,
         mostrarEndereco: true,
         url: url,
      };
      this.controller = new DadosDaEmpresaController(this);
      this.lang = this.props.lang;
   }

   renderizarFormulario = () => {
      return (
         <React.Fragment>
            <Row>
               <Col>
                  <Row>
                     <Col sm={3} md={3} lg={3}>
                        <Label>{this.lang.codigo}</Label>
                        <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                     </Col>
                     <Col>
                        <FormGroup>
                           <Label>{this.lang.telaDeCadastroDeEmpresa.situacao}</Label>
                           <Select
                              as='select'
                              name='situacao'
                              defaultValue={
                                 this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id
                              }
                              options={[
                                 { id: 1, descricao: this.lang.situacaoDeEmpresa.ativo },
                                 { id: 2, descricao: this.lang.situacaoDeEmpresa.inativo },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.situacao = i;
                                 });
                              }}
                              readOnly={true}
                           />
                        </FormGroup>
                     </Col>

                     <Col sm={3} xs={3} md={3}>
                        <FormGroup>
                           <Label>{this.lang.telaDeCadastroDeEmpresa.dataDeCadastro}</Label>
                           <DateInput
                              defaultValue={
                                 this.state.itemSelecionado.dataDeCadastro
                                    ? this.state.itemSelecionado.dataDeCadastro
                                    : new Date()
                              }
                              onChange={(value) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.dataDeCadastro = value;
                                 });
                              }}
                              readOnly={true}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col sm={12} md={12} lg={12}>
                        <FormGroup>
                           <Label>{this.lang.telaDeCadastroDeEmpresa.razaoSocial}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.razaoSocial}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.razaoSocial = e.target.value;
                                 });
                              }}
                              readOnly={true}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
               </Col>

               <Col style={{ textAlign: 'right', maxWidth: 150, marginLeft: -20 }}>
                  <Avatar
                     lang={this.props.lang}
                     api={this.props.api}
                     image={this.state.itemSelecionado.foto}
                     readOnly={true}
                  />
               </Col>
            </Row>
            <Row>
               <Col sm={8} md={8} lg={8}>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeEmpresa.nomeFantasia}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nomeFantasia}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nomeFantasia = e.target.value;
                           });
                        }}
                        readOnly={true}
                     />
                  </FormGroup>
               </Col>
               <Col sm={4} md={4} lg={4}>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeEmpresa.cnpj}</Label>
                     <CnpjInput
                        defaultValue={this.state.itemSelecionado.cnpj}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.cnpj = e.formattedValue;
                           });
                        }}
                        onBlur={this.controller.verificarSeCnpjJaExiste}
                        readOnly={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>               
               <Col >
                  <FormGroup>
                     <Label>{this.props.lang.telaDeCadastroDeEmpresa.senha}</Label>
                     {!this.state.esconderSenha && (
                        <React.Fragment>
                           <PasswordInput
                              defaultValue={this.state.itemSelecionado.senha ? '******' : null}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.senha = e.target.value;
                                    state.itemSelecionado.senhaAlterada = true;
                                 });
                              }}
                              appendIcon={faKey}
                           />
                        </React.Fragment>
                     )}
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeEmpresa.email}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.email}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.email = e.target.value;
                           });
                        }}
                        readOnly={true}
                     />
                  </FormGroup>
               </Col>               
            </Row>

            {this.telefones()}

            <br />

            {this.enderecos()}

            <br />
         </React.Fragment>
      );
   };

   telefones = () => {
      return (
         <SubCadastro
            titulo={this.lang.telaDeCadastroDeEmpresa.telefones}
            exibirTitulos={false}
            itens={this.state.itemSelecionado.telefones}
            lang={this.lang}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = {
                        pais: null,
                        ddd: null,
                        numero: null,
                        temWhatsApp: false,
                     };
                  }).then(() => resolve());
               });
            }}
            inserir={this.controller.inserirTelefone}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.telefones[index]));
                  }).then(() => resolve());
               });
            }}
            alterar={this.controller.alterarTelefone}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = null;
                  }).then(() => resolve());
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.telefones.splice(index, 1);
               });
            }}
            colunas={() => {
               return [{ titulo: '', width: '100%', className: null }];
            }}
            linha={(item) => {
               return [
                  <div style={{ display: 'flex' }}>
                     <div>{item.pais + ' ' + item.ddd + ' ' + item.numero + ' '} </div>
                     <div>{item.temWhatsApp ? <IconeWhatsApp key={'iconeWhatsApp'} /> : null}</div>
                  </div>,
               ];
            }}
            formulario={() => {
               return (
                  <div>
                     <Row>
                        <Col>
                           <div style={{ display: 'flex' }}>
                              <FormGroup>
                                 <Label>{this.lang.telefone.pais}</Label>
                                 <TextInput
                                    name='pais'
                                    defaultValue={this.state.telefoneSelecionado.pais}
                                    style={{ width: '60px' }}
                                    maxLength={3}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.pais = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup>
                                 <Label>{this.lang.telefone.ddd}</Label>
                                 <TextInput
                                    name='ddd'
                                    defaultValue={this.state.telefoneSelecionado.ddd}
                                    style={{ width: '60px' }}
                                    maxLength={2}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.ddd = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup>
                                 <Label>{this.lang.telefone.numero}</Label>
                                 <TextInput
                                    name='telefone'
                                    defaultValue={this.state.telefoneSelecionado.numero}
                                    maxLength={9}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.numero = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup
                                 controlId='chktemWhatsApp'
                                 style={{
                                    margin: 'auto',
                                    paddingLeft: '5px',
                                    fontWeight: 'normal',
                                    width: '100%',
                                 }}
                              >
                                 <CheckBox
                                    defaultChecked={this.state.telefoneSelecionado.temWhatsApp ? true : false}
                                    name='WhatsApp'
                                    label='WhatsApp'
                                    onChange={(checked) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.temWhatsApp = checked;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </div>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   enderecos = () => {
      return (
         <SubCadastro
            titulo={this.lang.telaDeCadastroDeEmpresa.enderecos}
            exibirTitulos={false}
            itens={this.state.itemSelecionado.enderecos}
            lang={this.lang}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = {
                        endereco: {},
                     };
                  }).then(() => resolve());
               });
            }}
            inserir={this.controller.inserirEndereco}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.enderecos[index]));
                  }).then(() => resolve());
               });
            }}
            alterar={this.controller.alterarEndereco}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = null;
                  }).then(() => resolve());
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.enderecos.splice(index, 1);
               });
            }}
            colunas={() => {
               return [
                  {
                     titulo: this.lang.telaDeCadastroDeEmpresa.enderecoCompleto,
                     width: '100%',
                     className: 'multilineTableData',
                  },
               ];
            }}
            linha={(item) => {
               return [getEnderecoCompleto(item.endereco)];
            }}
            formulario={() => {
               return this.state.mostrarEndereco && this.state.enderecoSelecionado ? (
                  <div>
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{this.lang.endereco.pais}</Label>
                              <Select
                                 name={'pais'}
                                 defaultValue={this.state.enderecoSelecionado.endereco.pais}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.enderecoSelecionado.endereco.pais = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return <PaisView api={this.props.api} select={select} lang={this.props.lang} />;
                                 }}
                                 asws={true}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/pais' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                                 }
                              />
                           </FormGroup>
                        </Col>
                        {(!this.state.enderecoSelecionado.endereco.pais ||
                           this.state.enderecoSelecionado.endereco.pais.codigo === '+55') && (
                           <Fragment>
                              <Col xs={2} md={2} xl={2}>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.cep}</Label>
                                    <CepInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.cep}
                                       onChange={(value) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.cep = value.formattedValue;
                                          });
                                       }}
                                       onBlur={() => {
                                          this.controller.consultarCep();
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.logradouro}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.logradouro}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.logradouro = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col style={{ maxWidth: 100 }}>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.numero}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.numero}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.numero = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={2} md={2} xl={2}>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.complemento}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.complemento}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.complemento = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                           </Fragment>
                        )}
                     </Row>
                     {(!this.state.enderecoSelecionado.endereco.pais ||
                        this.state.enderecoSelecionado.endereco.pais.codigo === '+55') && (
                        <Fragment>
                           <Row>
                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.bairro}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.bairro}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.bairro = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.cidade}</Label>
                                    <Select
                                       name={'cidade'}
                                       defaultValue={this.state.enderecoSelecionado.endereco.cidade}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.cidade = i;
                                          });
                                       }}
                                       formularioPadrao={(select) => {
                                          return (
                                             <CidadeView api={this.props.api} select={select} lang={this.props.lang} />
                                          );
                                       }}
                                       noDropDown={true}
                                       readOnlyColor='#ffff'
                                       getFilterUrl={(text) =>
                                          '/cidade' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                                       }
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        </Fragment>
                     )}

                     {this.state.enderecoSelecionado.endereco.pais &&
                        this.state.enderecoSelecionado.endereco.pais.codigo !== '+55' && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.endereco.linha1}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha1}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha1 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.endereco.linha2}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha2 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.endereco.linha3}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha3 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}
                  </div>
               ) : (
                  <div />
               );
            }}
         />
      );
   };

   editar = (item) => {
      if (this.form) {
         this.form.editarClick(item);
      }
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.lang.telaDeCadastroDeEmpresa.dadosDaEmpresa}
            url={url}
            api={this.props.api}
            lang={this.props.lang}
            id={this.props.id}
            apenasAlterar={true}
            ordenacaoPadrao={'id'}
            permissoes={[1361, null, 1363, null]}
            getFiltro={this.controller.getFiltro}            
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            ref={(c) => (this.form = c)}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               telefones: [],
               enderecos: [],
               situacao: { id: 1 },
            }}
         />
      );
   }
}
