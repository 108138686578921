import React from 'react';
import { Form } from 'react-bootstrap';
import '../contents/css/label.css';

export default class Label extends React.Component {
    render() {
        return (
            <Form.Label
                className='label'
                style={this.props.style}
                name={this.props.name}
                id={this.props.id}
                key={this.props.key}
                ref={this.props.ref}
            >
                {this.props.children}
            </Form.Label>
        );
    }
}
