import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Select from '../../components/Select';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import CepInput from '../../components/CepInput';
import DateInput from '../../components/DateInput';
import SubCadastro from '../../components/SubCadastro';
import Avatar from '../../components/Avatar';
import { Filler } from '../../components/Filler';
import { updateState, buildQueryString, getEnderecoCompleto, generatePassword, prepareSearchable } from '../../utils/Functions';
import EmpresaController from '../../controllers/Tecnometrics/EmpresaController';
import CheckBox from '../../components/CheckBox';
import { IconeWhatsApp } from '../../components/IconeWhatsApp';
import CidadeView from '../CidadeView';
import PaisView from '../PaisView';
import PasswordInput from '../../components/PasswordInput';
import { faKey, faTrash } from '@fortawesome/free-solid-svg-icons';
import EmailInput from '../../components/EmailInput';
import { showConfirm } from '../../components/Messages';
import SessionManager from '../../models/SessionManager';

const url = '/empresa';

export default class EmpresaView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         foto: null,
         mostrarEndereco: true,
         url: url,
         enviarNovaSenhaPorEmail: false,
         mostrarPlanilha: true,
         mostrarTendencia: true,
      };
      this.controller = new EmpresaController(this);
      this.lang = this.props.lang;
      let sessionManager = new SessionManager();
      this.login = sessionManager.getLogin();
   }

   camposPrincipais = () => {
      return (
         <React.Fragment>
            <Row>
               <Col>
                  <Row>
                     <Col sm={2} md={2} lg={2}>
                        <Label>{this.lang.codigo}</Label>
                        <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                     </Col>
                     <Col sm={3} xs={3} md={3}>
                        <FormGroup>
                           <Label>{this.lang.telaDeCadastroDeEmpresa.situacao}</Label>
                           <Select
                              as='select'
                              name='situacao'
                              defaultValue={
                                 this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id
                              }
                              options={[
                                 { id: 1, descricao: this.lang.situacaoDeEmpresa.ativo },
                                 { id: 2, descricao: this.lang.situacaoDeEmpresa.inativo },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.situacao = i;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>

                     <Col sm={3} xs={3} md={3}>
                        <Label>{this.lang.telaDeCadastroDeEmpresa.dataDeCadastro}</Label>
                        <DateInput
                           defaultValue={
                              this.state.itemSelecionado.dataDeCadastro
                                 ? this.state.itemSelecionado.dataDeCadastro
                                 : new Date()
                           }
                           onChange={(value) => {
                              updateState(this, (state) => {
                                 state.itemSelecionado.dataDeCadastro = value;
                              });
                           }}
                        />
                     </Col>

                     <Col>
                        <FormGroup>
                           <Label>{this.lang.endereco.pais}</Label>
                           <Select
                              name={'pais'}
                              defaultValue={this.state.itemSelecionado.pais}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.pais = i;
                                 });
                              }}
                              formularioPadrao={(select) => {
                                 return <PaisView api={this.props.api} select={select} lang={this.props.lang} />;
                              }}
                              asws={true}
                              noDropDown={true}
                              readOnlyColor='#ffff'
                              getFilterUrl={(text) => '/pais' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{this.lang.telaDeCadastroDeEmpresa.tipoDeContrato}</Label>
                           <Select
                              as='select'
                              name='tipoDeContrato'
                              defaultValue={
                                 this.state.itemSelecionado.tipoDeContrato &&
                                 this.state.itemSelecionado.tipoDeContrato.id
                              }
                              options={[
                                 {
                                    id: 1,
                                    descricao: this.lang.telaDeCadastroDeEmpresa.tiposDeContrato.medTrackComMapa,
                                 },
                                 {
                                    id: 2,
                                    descricao: this.lang.telaDeCadastroDeEmpresa.tiposDeContrato.medTrackSemMapa,
                                 },
                                 { id: 3, descricao: this.lang.telaDeCadastroDeEmpresa.tiposDeContrato.senseTrack },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.tipoDeContrato = i;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>

                     <Col>
                        <FormGroup>
                           <Label>{this.lang.telaDeCadastroDeEmpresa.situacaoDoContrato}</Label>
                           <Select
                              as='select'
                              name='situacaoDoContrato'
                              defaultValue={
                                 this.state.itemSelecionado.situacaoDoContrato &&
                                 this.state.itemSelecionado.situacaoDoContrato.id
                              }
                              options={[
                                 { id: 1, descricao: this.lang.situacaoDeContratoEmpresa.ativo },
                                 { id: 2, descricao: this.lang.situacaoDeContratoEmpresa.bloqueado },
                                 { id: 3, descricao: this.lang.situacaoDeContratoEmpresa.encerrado },
                              ]}
                              getKeyValue={(i) => i.id}
                              getDescription={(i) => i.descricao}
                              onSelect={(i) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.situacaoDoContrato = i;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col sm={12} md={12} lg={12}>
                        <FormGroup>
                           <Label>{this.lang.telaDeCadastroDeEmpresa.razaoSocial}</Label>
                           <TextInput
                              defaultValue={this.state.itemSelecionado.razaoSocial}
                              upperCase={true}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.razaoSocial = e.target.value;
                                 });
                              }}
                           />
                        </FormGroup>
                     </Col>
                  </Row>
               </Col>

               <Col style={{ textAlign: 'right', maxWidth: 150, marginLeft: -20 }}>
                  <Avatar
                     lang={this.props.lang}
                     api={this.props.api}
                     image={this.state.itemSelecionado.foto}
                     onChange={(image) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.foto = image;
                        });
                     }}
                  />
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeEmpresa.nomeFantasia}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nomeFantasia}
                        upperCase={true}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nomeFantasia = e.target.value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeEmpresa.cnpj}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.cnpj}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.cnpj = e.target.value;
                           });
                        }}
                        onBlur={this.controller.verificarSeCnpjJaExiste}
                     />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{this.props.lang.telaDeCadastroDeEmpresa.senha}</Label>
                     {!this.state.esconderSenha && (
                        <React.Fragment>
                           <PasswordInput
                              defaultValue={this.state.itemSelecionado.senha ? '******' : null}
                              readOnly={this.state.itemSelecionado.senha && !this.state.itemSelecionado.senhaAlterada}
                              onChange={(e) => {
                                 updateState(this, (state) => {
                                    state.itemSelecionado.senha = e.target.value;
                                    state.itemSelecionado.senhaAlterada = true;
                                    state.itemSelecionado.enviarNovaSenhaPorEmail = false;
                                 });
                              }}
                              appendIcon={this.state.itemSelecionado.senha ? faTrash : faKey}
                              appendTitle={this.props.lang.gerarNovaSenha}
                              onAppendClick={() => {
                                 if (this.state.itemSelecionado.senha) {
                                    showConfirm(
                                       this.props.lang.desejaLimparASenha,
                                       () => {
                                          updateState(this, (state) => {
                                             state.itemSelecionado.senha = null;
                                             state.itemSelecionado.senhaAlterada = true;
                                             state.esconderSenha = true;
                                          }).then(() => {
                                             updateState(this, (state) => {
                                                state.esconderSenha = false;
                                             });
                                          });
                                       },
                                       null,
                                       this.props.lang.sim,
                                       this.props.lang.nao
                                    );
                                 } else {
                                    showConfirm(
                                       this.props.lang.desejaRealmenteGerarUmaNovaSenha,
                                       () => {
                                          updateState(this, (state) => {
                                             state.itemSelecionado.senha = generatePassword(true, 8);
                                             state.itemSelecionado.senhaAlterada = true;
                                             state.itemSelecionado.enviarNovaSenhaPorEmail = true;
                                             state.esconderSenha = true;
                                          }).then(() => {
                                             updateState(this, (state) => {
                                                state.esconderSenha = false;
                                             });
                                          });
                                       },
                                       null,
                                       this.props.lang.sim,
                                       this.props.lang.nao
                                    );
                                 }
                              }}
                           />
                           {this.state.itemSelecionado.senhaAlterada && this.state.itemSelecionado.senha && (
                              <CheckBox
                                 defaultChecked={this.state.itemSelecionado.enviarNovaSenhaPorEmail ? true : false}
                                 name='enviarNovaSenhaPorEmail'
                                 label={this.props.lang.enviarANovaSenhaPorEmail}
                                 onChange={(checked) => {
                                    updateState(this, (state) => {
                                       state.itemSelecionado.enviarNovaSenhaPorEmail = checked;
                                    });
                                 }}
                              />
                           )}
                        </React.Fragment>
                     )}
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeEmpresa.email}</Label>
                     <EmailInput
                        defaultValue={this.state.itemSelecionado.email}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.email = e.target.value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
         </React.Fragment>
      );
   };

   telefones = () => {
      return (
         <SubCadastro
            titulo={this.lang.telaDeCadastroDeEmpresa.telefones}
            exibirTitulos={false}
            itens={this.state.itemSelecionado.telefones}
            lang={this.lang}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = {
                        pais: '+55',
                        ddd: null,
                        numero: null,
                        temWhatsApp: false,
                     };
                  }).then(() => resolve());
               });
            }}
            inserir={this.controller.inserirTelefone}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.telefones[index]));
                  }).then(() => resolve());
               });
            }}
            alterar={this.controller.alterarTelefone}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = null;
                  }).then(() => resolve());
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.telefones.splice(index, 1);
               });
            }}
            colunas={() => {
               return [{ titulo: '', width: '100%', className: null }];
            }}
            linha={(item) => {
               return [
                  <div style={{ display: 'flex' }}>
                     <div>{item.pais + ' ' + item.ddd + ' ' + item.numero + ' '} </div>
                     <div>{item.temWhatsApp ? <IconeWhatsApp key={'iconeWhatsApp'} /> : null}</div>
                  </div>,
               ];
            }}
            formulario={() => {
               return (
                  <div>
                     <Row>
                        <Col>
                           <div style={{ display: 'flex' }}>
                              <FormGroup>
                                 <Label>{this.lang.telefone.pais}</Label>
                                 <TextInput
                                    name='pais'
                                    defaultValue={this.state.telefoneSelecionado.pais}
                                    style={{ width: '60px' }}
                                    maxLength={3}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.pais = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup>
                                 <Label>{this.lang.telefone.ddd}</Label>
                                 <TextInput
                                    name='ddd'
                                    defaultValue={this.state.telefoneSelecionado.ddd}
                                    style={{ width: '60px' }}
                                    maxLength={2}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.ddd = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup>
                                 <Label>{this.lang.telefone.numero}</Label>
                                 <TextInput
                                    name='telefone'
                                    defaultValue={this.state.telefoneSelecionado.numero}
                                    maxLength={9}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.numero = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup
                                 controlId='chktemWhatsApp'
                                 style={{
                                    margin: 'auto',
                                    paddingLeft: '5px',
                                    fontWeight: 'normal',
                                    width: '100%',
                                 }}
                              >
                                 <CheckBox
                                    defaultChecked={this.state.telefoneSelecionado.temWhatsApp ? true : false}
                                    name='WhatsApp'
                                    label='WhatsApp'
                                    onChange={(checked) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.temWhatsApp = checked;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </div>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   enderecos = () => {
      return (
         <SubCadastro
            titulo={this.lang.telaDeCadastroDeEmpresa.enderecos}
            exibirTitulos={false}
            itens={this.state.itemSelecionado.enderecos}
            lang={this.lang}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = {
                        endereco: {
                           pais: { id: 1, nome: 'Brasil', codigo: '+55' },
                        },
                     };
                  }).then(() => resolve());
               });
            }}
            inserir={this.controller.inserirEndereco}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.enderecos[index]));
                  }).then(() => resolve());
               });
            }}
            alterar={this.controller.alterarEndereco}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.enderecoSelecionado = null;
                  }).then(() => resolve());
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.enderecos.splice(index, 1);
               });
            }}
            colunas={() => {
               return [
                  {
                     titulo: this.lang.telaDeCadastroDeEmpresa.enderecoCompleto,
                     width: '100%',
                     className: 'multilineTableData',
                  },
               ];
            }}
            linha={(item) => {
               return [getEnderecoCompleto(item.endereco)];
            }}
            formulario={() => {
               return this.state.mostrarEndereco && this.state.enderecoSelecionado ? (
                  <div>
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{this.lang.endereco.pais}</Label>
                              <Select
                                 name={'pais'}
                                 defaultValue={this.state.enderecoSelecionado.endereco.pais}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                                 onSelect={(i) => {
                                    updateState(this, (state) => {
                                       state.enderecoSelecionado.endereco.pais = i;
                                    });
                                 }}
                                 formularioPadrao={(select) => {
                                    return <PaisView api={this.props.api} select={select} lang={this.props.lang} />;
                                 }}
                                 asws={true}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/pais' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                                 }
                              />
                           </FormGroup>
                        </Col>
                        {(!this.state.enderecoSelecionado.endereco.pais ||
                           this.state.enderecoSelecionado.endereco.pais.codigo === '+55') && (
                           <Fragment>
                              <Col xs={2} md={2} xl={2}>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.cep}</Label>
                                    <CepInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.cep}
                                       onChange={(value) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.cep = value.formattedValue;
                                          });
                                       }}
                                       onBlur={() => {
                                          this.controller.consultarCep();
                                       }}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.logradouro}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.logradouro}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.logradouro = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col style={{ maxWidth: 100 }}>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.numero}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.numero}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.numero = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col xs={2} md={2} xl={2}>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.complemento}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.complemento}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.complemento = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                           </Fragment>
                        )}
                     </Row>
                     {(!this.state.enderecoSelecionado.endereco.pais ||
                        this.state.enderecoSelecionado.endereco.pais.codigo === '+55') && (
                        <Fragment>
                           <Row>
                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.bairro}</Label>
                                    <TextInput
                                       defaultValue={this.state.enderecoSelecionado.endereco.bairro}
                                       onChange={(e) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.bairro = e.target.value;
                                          });
                                       }}
                                       upperCase={true}
                                    />
                                 </FormGroup>
                              </Col>
                              <Col>
                                 <FormGroup>
                                    <Label>{this.lang.endereco.cidade}</Label>
                                    <Select
                                       name={'cidade'}
                                       defaultValue={this.state.enderecoSelecionado.endereco.cidade}
                                       getKeyValue={(i) => i.id}
                                       getDescription={(i) => (i && i.nome ? i.nome.toUpperCase() : null)}
                                       onSelect={(i) => {
                                          updateState(this, (state) => {
                                             state.enderecoSelecionado.endereco.cidade = i;
                                          });
                                       }}
                                       formularioPadrao={(select) => {
                                          return (
                                             <CidadeView api={this.props.api} select={select} lang={this.props.lang} />
                                          );
                                       }}
                                       noDropDown={true}
                                       readOnlyColor='#ffff'
                                       getFilterUrl={(text) =>
                                          '/cidade' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                                       }
                                    />
                                 </FormGroup>
                              </Col>
                           </Row>
                        </Fragment>
                     )}

                     {this.state.enderecoSelecionado.endereco.pais &&
                        this.state.enderecoSelecionado.endereco.pais.codigo !== '+55' && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.endereco.linha1}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha1}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha1 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.endereco.linha2}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha2}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha2 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.lang.endereco.linha3}</Label>
                                       <TextInput
                                          defaultValue={this.state.enderecoSelecionado.endereco.linha3}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.enderecoSelecionado.endereco.linha3 = e.target.value;
                                             });
                                          }}
                                          upperCase={true}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}
                  </div>
               ) : (
                  <div />
               );
            }}
         />
      );
   };

   renderizarFormulario = () => {
      return (
         <React.Fragment>
            {this.camposPrincipais()}

            {this.telefones()}

            <br />

            {this.enderecos()}

            <br />

            <Filler height={50} />

            <br />
         </React.Fragment>
      );
   };

   calcularTendencia = (state) => {
      try {
         state.resultadoSelecionado.tendency = Math.abs(
            state.resultadoSelecionado.referenceValue - state.resultadoSelecionado.measuredValue
         );
         state.resultadoSelecionado.percentTendency =
            (state.resultadoSelecionado.tendency * 100) / state.resultadoSelecionado.referenceValue;
      } catch (e) {}
      state.mostrarTendencia = false;
   };

   editar = (item) => {
      if (this.form) {
         this.form.editarClick(item);
      }
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.lang.telaDeCadastroDeEmpresa.titulo}
            url={url}
            fastUrl={url + '/fast'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id'}
            permissoes={[1101, 1102, 1103, 1104]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            ref={(c) => (this.form = c)}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               telefones: [],
               enderecos: [],
               situacao: { id: 1 },
               pais: { id: 1, nome: 'Brasil', codigo: '+55' },
            }}
         />
      );
   }
}
