import React from 'react';
import { LayoutParams } from '../config/LayoutParams';
import './../contents/css/logo-menu-inferior.css';

export const LogoMenuInferior = () => {
   return (
      <div className='div-logo-menu-inferior'>         
         <img alt='' src={LayoutParams.imgHomeLogo} className='logo-menu-inferior' />
      </div>
   );
};
