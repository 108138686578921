import React from 'react';
import { FormControl } from 'react-bootstrap';
import '../contents/css/text-input.css';

export default class EmailInput extends React.Component {
    render() {
        return (
            <FormControl
                type='email'
                className={'text-input' + (this.props.className ? ' ' + this.props.className : '')}
                style={this.props.style}
                name={this.props.name}
                id={this.props.id}
                key={this.props.key}
                ref={this.props.ref}
                maxLength={this.props.maxLength}
                onChange={this.props.onChange}
                defaultValue={this.props.defaultValue}
                placeholder={this.props.placeholder}
                onInput={(e) => {
                    e.target.value = ('' + e.target.value).toLowerCase();
                }}
                readOnly={this.props.readOnly}
            />
        );
    }
}
