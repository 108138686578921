import { showError } from '../../components/Messages';
import { BaseController } from '../BaseController';

export default class PredioController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'PredioController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDePredio.nome, width: '80%', orderby: 'Nome' },      
         { titulo: this.lang.telaDeCadastroDePredio.situacao, width: '20%', orderby: 'situacao' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.nome, item.situacao ? item.situacao.descricao : null];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.nome) {
            showError(this.lang.telaDeCadastroDePredio.mensagens.informeONomeDoPredio);
            reject();
            return;
         }
 
         var input = {
            nome: item.nome,
            situacao: item.situacao ? item.situacao.id : null,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };
}
