import React, { Component } from 'react';
import FormularioPadrao from './FormularioPadrao';
import PackageController from '../controllers/PackageController';
import { Col, Row, FormGroup } from 'react-bootstrap';
import { dateTimeToString, numberToString } from '../utils/Functions';
import Label from '../components/Label';
import TextInput from '../components/TextInput';
import Button from '../components/Button';
import { showInfo } from '../components/Messages';
import styled from 'styled-components';

export default class PackageView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.controller = new PackageController(this);
   }

   renderizarFormulario = () => {
      return (
         <React.Fragment>
            <Row>
               <Col sm={6} md={2} lg={2}>
                  <FormGroup>
                     <Label>{'Código'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{'Data da leitura'}</Label>
                     <TextInput readOnly defaultValue={dateTimeToString(this.state.itemSelecionado.dataDaLeitura)} />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{'Gateway'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.gateway} />
                  </FormGroup>
               </Col>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{'TAG'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.mac} />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{'Distância (m)'}</Label>
                     <TextInput readOnly defaultValue={numberToString(this.state.itemSelecionado.distancia)} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{'Bateria (v)'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.bateria} />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{'Temperatura (°C)'}</Label>
                     <TextInput readOnly defaultValue={numberToString(this.state.itemSelecionado.temperatura)} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{'Umidade (%)'}</Label>
                     <TextInput readOnly defaultValue={numberToString(this.state.itemSelecionado.umidade)} />
                  </FormGroup>
               </Col>
            </Row>
         </React.Fragment>
      );
   };

   renderizarFiltros = () => {
      return (
         <div>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>Gateway</Label>
                     <TextInput onChange={(e) => this.setState({ gateway: e.target.value })} />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>TAG</Label>
                     <TextInput onChange={(e) => this.setState({ tag: e.target.value })} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>&nbsp;</Label>
                     <Button text={'Filtrar'} onClick={this.form.filtrar} />
                  </FormGroup>
               </Col>
            </Row>
         </div>
      );
   };

   getFiltro = () => {
      var result = {
         gateway: this.state.gateway,
         mac: this.state.tag,
      };
      return result;
   };

   render() {
      return (
         <FormularioPadrao
            titulo={'Pacotes'}
            url={'/package'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id desc'}
            ref={(c) => (this.form = c)}
            permissoes={[true, null, null, null]}
            getFiltro={this.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            esconderFiltro={true}
            renderizarFiltros={this.renderizarFiltros}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{}}
            menus={[
               {
                  titulo: 'Informação',
                  subMenus: [
                     {
                        style: {
                           cursor: 'default',
                           backgroundColor: 'white',
                           color: 'black'
                        },
                        conteudo: (
                           <div style={{ fontSize: 12, width: 400, }}>
                              <span style={{ whiteSpace: 'break-spaces' }}>
                                 Esse é o buffer dinâmico dos pacotes recebidos diretamente dos gateways. <br />
                                 Não existe tratamento de informações, portante os gateways e tags cadastrados podem não
                                 existir no sistema.
                                 <br />O tempo máximo de permanência de uma leitura no buffer é de 2 minutos.
                              </span>
                           </div>
                        ),
                     },
                  ],
               },
            ]}
         />
      );
   }
}
