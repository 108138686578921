import { showError } from '../../components/Messages';
import { buildQueryString } from '../../utils/Functions';
import { BaseController } from './../BaseController';

export default class PerfilDeUsuarioController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'PerfilDeUsuarioController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDePerfilDeUsuario.nome, width: '80%', orderby: 'Nome' },
         { titulo: this.lang.telaDeCadastroDePerfilDeUsuario.situacao, width: '20%', orderby: null },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.nome, item.situacao ? item.situacao.descricao : null];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.nome) {
            showError(this.lang.telaDeCadastroDePerfilDeUsuario.mensagens.informeONomeDoPerfilDeUsuario);
            reject();
            return;
         }

         var input = {
            nome: item.nome,
            situacao: item.situacao ? item.situacao.id : 0,
            acessos: item.acessos,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };

   aposInserir = () => {
      this.inserirTodas();
   };

   inserirTodas = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         this.api
            .get(
               '/rotinadosistema' +
                  buildQueryString(null, null, 'id', {
                     Searchable: '',
                     DisponivelParaEmpresa: true,
                  })
            )
            .then((result) => {
               state.itemSelecionado.acessos = [];

               result.items.forEach((rotina) => {
                  state.itemSelecionado.acessos.push({ rotina: rotina });
               });

               this.view.setState({ itemSelecionado: state.itemSelecionado }, () => {
                  this.view.setState({ mostrarAcessos: false }, () => this.view.setState({ mostrarAcessos: true }));
               });

               resolve();
            })
            .catch(reject);
      });
   };
}
