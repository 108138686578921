import React, { Component } from 'react';
import { FormControl, Modal, InputGroup } from 'react-bootstrap';
import { faCaretUp, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import IconButton from './IconButton';
import { inputToUpper } from '../utils/Functions';
import Api from '../utils/Api';
import styled from 'styled-components';
import '../contents/css/select.css';
import { create_UUID } from '../utils/Functions';
import SessionManager from '../models/SessionManager';
import { getLang } from '../models/Lang';
import { LayoutParams } from '../config/LayoutParams'; 

const corDoIcone = LayoutParams.colors.corSecundaria;

export default class Select extends Component {
   constructor(props) {
      super(props);
      var noDropDown = props.noDropDown || props.readOnly;
      this.state = {
         inserindo: false,
         searchText: null,
         oneItemSelected: noDropDown && props.defaultValue ? props.defaultValue : null,
         lastFilter: null,
         previewOptions: null,
         noDropDown: noDropDown,
      };
      this.getOptions = this.getOptions.bind(this);
      this.getDefault = this.getDefault.bind(this);
      this.getSearchText = this.getSearchText.bind(this);
      this.aoSelecionar = this.aoSelecionar.bind(this);
      this.clear = this.clear.bind(this);
      this.showModal = this.showModal.bind(this);
      this.filter = this.filter.bind(this);
      this.api = new Api();
      this.name = this.props.name ? this.props.name : create_UUID();

      let sessionManager = new SessionManager();
      let login = sessionManager.getLogin();
      this.idioma = login ? (login.idioma ? login.idioma : 'pt-BR') : 'pt-BR';
      this.lang = getLang(this.idioma);
   }

   getOptions() {
      let result = [];
      if (!this.props.options && !this.state.noDropDown) {
         console.warn('Opções não definidas para o componente ' + this.props.name + '.');
      } else {
         result = this.props.filter ? this.props.options.filter(this.props.filter) : this.props.options;
      }
      return result;
   }

   getDefault() {
      let result = this.props.defaultValue;
      if (!result || (result === 0 && this.props.acceptZero)) {
         let options = this.props.filter ? this.props.options.filter(this.props.filter) : this.props.options;
         if (options) {
            // Auto Select When Single Option
            if (this.props.asws && options.length === 1) {
               result = this.props.getKeyValue(options[0]);
               this.onSelect(options[0]);
            }
            if (this.props.allowEmpty === false && options.length > 0 && (result === null || result === undefined)) {
               result = this.props.getKeyValue(options[0]);
               this.onSelect(options[0]);
            }
         }
      }
      return result;
   }

   showModal() {
      this.setState({ inserindo: true });
   }

   componentDidMount() {
      let result = this.props.defaultValue;
      if (!result && this.props.asws) {
         if (this.props.options && this.props.options.length === 1) {
            this.onSelect(this.props.options[0]);
         }
      }
      if (!result && this.props.acceptZero) {
         if (this.props.options) {
            let zeroOptions = this.props.options.filter((i) => this.props.getKeyValue(i) === 0);
            if (zeroOptions.length > 0) {
               this.onSelect(zeroOptions[0]);
            }
         }
      }
   }

   onSelect = (item) => {
      if (
         item &&
         this.lastSelectedOption &&
         this.props.getKeyValue(item) === this.props.getKeyValue(this.lastSelectedOption)
      ) {
         return;
      }
      this.lastSelectedOption = item;
      if (this.props.onSelect) {
         this.props.onSelect(item);
      }
   };

   aoSelecionar(item, novo) {
      if (item) {
         if (novo && !this.state.noDropDown) {
            this.props.options.push(item);
         }
         this.onSelect(item);
         if (!this.state.noDropDown) {
            if (this.props.updateOptions) {
               this.props.updateOptions(this.props.options);
            }
            this.select.value = this.props.getKeyValue(item);
         } else {
            if (this.props.getDescription && this.input) {
               this.input.value = this.props.getDescription(item);
            }
            this.setState({ oneItemSelected: item });
         }

         this.setState({
            inserindo: false,
         });
      }
   }

   aoCancelar() {
      this.setState({
         inserindo: false,
      });
   }

   getSearchText() {
      return this.state.searchText;
   }

   clear() {
      let f = () => {
         this.input.value = null;
         this.setState({
            oneItemSelected: null,
            searchText: null
         });
         this.onSelect(null);
      };

      if (this.props.beforeClear) {
         this.this.props.beforeClear().then(f);
      } else {
         f();
      }
   }

   filter(text) {
      if (this.state.noDropDown && this.props.getFilterUrl) {
         if (text && text.length >= 3) {
            if (text !== this.state.lastFilter) {
               if (this.waitingData) {
                  return;
               }

               this.waitingData = true;

               this.api
                  .getAll(this.props.getFilterUrl(text))
                  .then((result) => {
                     if (this.props.filter) {
                        result = this.props.filter(result.items);
                     }
                     this.setState({ previewOptions: result.items });
                  })
                  .finally(() => {
                     this.waitingData = false;
                  });
            }
         } else {
            this.setState({ previewOptions: null });
         }
      }
   }

   getReadOnlyColor = () => {
      return this.props.readOnlyColor ? this.props.readOnlyColor : '#ffff';
   };

   setFocus = (i) => {
      if (!this.state.previewOptions) {
         return;
      }

      if (i <= 0) {
         return;
      }

      if (!this.previewFocus) {
         this.previewFocus = 0;
      }

      let item = document.getElementById(this.name + '__' + (this.previewFocus + i));
      if (item) {
         this.previewFocus = this.previewFocus + i;
         if (this.previewFocus > this.state.previewOptions.length) {
            this.previewFocus = this.state.previewOptions.length;
         }
         item.focus();
      }
   };

   render() {
      var _default = this.getDefault();
      let defaultDescription = null;
      let defaultKeyValue = null;

      if (_default !== null && _default !== undefined) {
         if (this.props.getDescription) {
            try {
               defaultDescription = this.props.getDescription(_default);
            } catch (e) {
               console.error(e);
               console.error('Valor:', this.props.defaultValue);
               console.error('_default', _default);
            }
         }

         if (typeof _default === 'object') {
            if (this.props.getKeyValue) {
               defaultKeyValue = this.props.getKeyValue(_default);
            }
         } else {
            defaultKeyValue = _default;
         }
      }

      let name = this.props.name ? this.props.name : 'selectName';
      return (
         <div id={'select_' + name}>
            {this.props.searchOnly && (
               <IconButton
                  id={'icon'}
                  style={{
                     fontSize: 10,
                     color: corDoIcone,
                     width: 38,
                     paddingTop: 4,
                     height: 28,
                  }}
                  onClick={() => {
                     this.setState({
                        inserindo: true,
                     });
                  }}
                  icon={faCaretUp}
               />
            )}

            {!this.props.searchOnly && (
               <InputGroup style={{ flexWrap: 'nowrap' }}>
                  <React.Fragment>
                     {!this.state.noDropDown && (
                        <FormControl
                           as='select'
                           className='select-dropdown-control'
                           ref={(c) => (this.select = c)}
                           name={this.props.name ? this.props.name : 'selectName'}
                           id={this.name}
                           title={defaultDescription}
                           style={{
                              height: this.props.height ? this.props.height : 38,
                              padding: 1,
                              backgroundColor: this.props.color,
                              cursor: this.props.cursor ? this.props.cursor : 'default',
                              outline: 'none',
                              borderColor: '#ced4da',
                              fontSize: this.props.fontSize ? this.props.fontSize : null,
                           }}
                           defaultValue={defaultKeyValue}
                           onChange={(e) => {
                              let options = this.props.options.filter((i) => {
                                 let value = this.props.getKeyValue ? this.props.getKeyValue(i) : null;
                                 return (value || (value === 0 && this.props.acceptZero)) &&
                                    value.toString() === e.target.value
                                    ? true
                                    : false;
                              });
                              let option = options[0];
                              this.onSelect(option);
                           }}
                           disabled={this.props.disabled}
                           readOnly={this.props.readOnly}
                        >
                           {[
                              (this.props.allowEmpty === undefined || this.props.allowEmpty === null) && (
                                 <option key={-1} value=''>
                                    {this.props.nullText !== null && this.props.nullText !== undefined
                                       ? this.props.nullText
                                       : this.lang.selecione}
                                 </option>
                              ),
                              this.getOptions(this.props.options).map((item, index) => {
                                 return (
                                    <option
                                       key={index}
                                       value={this.props.getKeyValue ? this.props.getKeyValue(item) : null}
                                    >
                                       {this.props.getDescription ? this.props.getDescription(item) : null}
                                    </option>
                                 );
                              }),
                           ]}
                        </FormControl>
                     )}

                     {this.state.noDropDown && (
                        <form
                           onSubmit={(event) => {
                              event.preventDefault();
                              this.setState({
                                 inserindo: true,
                              });
                           }}
                           action='/'
                           name={'formSearchText_' + name}
                           id={'formSearchText_' + name}
                           style={{ width: '100%' }}
                        >
                           <FormControl
                              type='text'
                              className='select-text-control'
                              ref={(c) => (this.input = c)}
                              name={this.props.name ? this.props.name : 'selectName'}
                              id={this.name}
                              defaultValue={defaultDescription}
                              onChange={(e) => {
                                 this.setState({ searchText: e.target.value });
                                 this.changed = true;
                                 clearTimeout(this.changeTimer);
                                 this.changeTimer = setTimeout(() => {
                                 this.filter(e.target.value);
                                 }, 100);
                              }}
                              title={defaultDescription}
                              style={{
                                 backgroundColor:
                                    (this.props.readOnly || this.state.oneItemSelected) && this.getReadOnlyColor()
                                       ? this.getReadOnlyColor()
                                       : this.props.color,
                                 borderTopRightRadius: this.props.readOnly ? null : 0,
                                 borderBottomRightRadius: this.props.readOnly ? null : 0,
                                 outline: 'none',
                                 boxShadow: 'none',
                                 borderColor: '#ced4da',
                                 fontSize: this.props.fontSize ? this.props.fontSize : null,
                              }}
                              readOnly={
                                 this.props.readOnly || this.state.oneItemSelected || this.props.disableTextEdit
                                    ? true
                                    : false
                              }
                              placeholder={this.props.placeholder}
                              onInput={inputToUpper}
                              onBlur={() => {
                                 setTimeout(() => {
                                    this.setState({ previewOptions: null });
                                 }, 1500);
                              }}
                              onKeyUp={(e) => {
                                 if (e.keyCode === 40) {
                                    this.setFocus(1);
                                 }
                                 if (e.keyCode === 38) {
                                    this.setFocus(-1);
                                 }
                              }}
                           ></FormControl>
                        </form>
                     )}

                     {this.props.formularioPadrao && !this.props.readOnly && (
                        <InputGroup.Append className='hide-when-readonly' style={{ cursor: 'pointer' }}>
                           {!this.state.oneItemSelected && (
                              <InputGroup.Text
                                 style={{maxWidth: 48}}
                                 onClick={() => {
                                    this.setState({
                                       inserindo: true,
                                    });
                                 }}
                              >
                                 <div style={{ height: 24, width: 30, display: 'table-cell'}}>
                                    <IconButton
                                       style={{
                                          fontSize: 22,
                                          paddingTop: 3,
                                          color: corDoIcone,
                                       }}
                                       icon={this.state.noDropDown ? faSearch : faSearch}
                                    />
                                 </div>
                              </InputGroup.Text>
                           )}
                           {this.state.oneItemSelected && (
                              <InputGroup.Text style={{ cursor: 'pointer' }} onClick={this.clear}>
                                 <React.Fragment>
                                    <IconButton
                                       style={{
                                          fontSize: 22,
                                          paddingTop: 3,
                                          color: LayoutParams.colors.corSecundaria,
                                       }}
                                       icon={faTimesCircle}
                                    />
                                 </React.Fragment>
                              </InputGroup.Text>
                           )}
                        </InputGroup.Append>
                     )}
                  </React.Fragment>
               </InputGroup>
            )}

            {this.state.noDropDown && this.state.previewOptions && this.state.previewOptions.length > 0 && (
               <div style={{ position: 'relative', zIndex: 999 }}>
                  <DropDownStyled>
                     {this.state.previewOptions &&
                        this.state.previewOptions.map((item, index) => {
                           return (
                              <div
                                 key={index}
                                 tabIndex={1000 + index}
                                 id={this.name + '__' + (index + 1)}
                                 style={{
                                    width: '100%',
                                    border: '1px solid lightgray',
                                    padding: 8,
                                    cursor: 'pointer',
                                    color: this.props.dropDownTextColor ? this.props.dropDownTextColor : '#555',
                                 }}
                                 onClick={() => {
                                    this.aoSelecionar(item);
                                    this.setState({ previewOptions: null });
                                 }}
                                 onKeyUp={(e) => {
                                    if (e.keyCode === 40) {
                                       this.setFocus(1);
                                    }
                                    if (e.keyCode === 38) {
                                       this.setFocus(-1);
                                    }
                                    if (e.keyCode === 13) {
                                       this.aoSelecionar(item);
                                       this.setState({ previewOptions: null });
                                       let component = document.getElementById(this.name);
                                       if (component) {
                                          component.focus();
                                       }
                                    }
                                    if (e.keyCode === 27) {
                                       this.aoSelecionar(null);
                                       this.setState({ previewOptions: null });
                                       let component = document.getElementById(this.name);
                                       if (component) {
                                          component.focus();
                                       }
                                    }
                                 }}
                              >
                                 <span>{this.props.getDescription(item)}</span>
                              </div>
                           );
                        })}
                  </DropDownStyled>
               </div>
            )}

            {this.state.inserindo && (
               <Modal
                  show={this.state.inserindo}
                  scrollable={true}
                  size={'lg'}
                  onHide={() => {}}
                  onKeyDown={(e) => {
                     if (e.keyCode === 27) this.setState({ inserindo: false });
                  }}
                  dialogClassName='h-100'
               >
                  <Modal.Body
                     style={{
                        overflow: 'hidden',
                        display: 'flex',
                        position: 'relative',
                        fontSize: 13,
                        padding: '0 0 0 0',
                        maxHeight: '100%',
                     }}
                  >
                     {this.props.formularioPadrao(this)}
                  </Modal.Body>
               </Modal>
            )}
         </div>
      );
   }
}

const DropDownStyled = styled.div`
   width: ${(props) => props.width};
   position: absolute;
   top: -3px;
   left: 0px;
   width: 100%;
   background-color: white;
   z-index: 1;

   div:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
   }
`;
