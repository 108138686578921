import React from 'react';
import NumberFormat from 'react-number-format';
import '../contents/css/telefone-fixo-input.css';

export default class TelefoneFixoInput extends React.Component {
    render() {
        return (
            <NumberFormat
                className={
                    'form-control telefone-fixo-input' + (this.props.className ? ' ' + this.props.className : '')
                }
                format='(##)####-####'
                placeholder='(00)0000-0000'
                defaultValue={this.props.defaultValue}
                onValueChange={(value) => {
                    if (this.props.onChange) {
                        this.props.onChange(value.formattedValue);
                    }
                }}
                style={this.props.style}
                name={this.props.name}
                id={this.props.id}
                key={this.props.key}
                ref={this.props.ref}
            />
        );
    }
}
