import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ReactDOM from 'react-dom';
import App from '../../App';
import SessionManager from '../../models/SessionManager';

export default class LogoffView extends Component {
   render() {
      let sessionManager = new SessionManager();

      if (sessionManager.isAuthenticated() && sessionManager.isUsuarioTecnometricsSupervisor()) {
         sessionStorage.removeItem('usuario');
         ReactDOM.render(<App />, document.getElementById('root'));
         return <Redirect to={{ pathname: '/supervisor' }} />;
      } else if (sessionManager.isAuthenticated() && sessionManager.isUsuarioTecnometrics()) {
         sessionStorage.removeItem('usuario');
         ReactDOM.render(<App />, document.getElementById('root'));
         return <Redirect to={{ pathname: '/adm' }} />;
      } else {
         sessionStorage.removeItem('usuario');
         ReactDOM.render(<App />, document.getElementById('root'));
         return <Redirect to={{ pathname: '/' }} />;
      }
   }
}
