import { showError } from '../../components/Messages';
import { rsaEncrypt, updateState, validarTelefone } from '../../utils/Functions';
import { BaseController } from '../BaseController';
import btoa from 'btoa';

export default class UsuarioDeEmpresaController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'UsuarioDeEmpresaController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.cadastroDeUsuarioDeEmpresa.login, width: '20%', orderby: null },
         { titulo: this.lang.cadastroDeUsuarioDeEmpresa.nome, width: '40%', orderby: null },
         { titulo: this.lang.cadastroDeUsuarioDeEmpresa.email, width: '20%', orderby: null },
         { titulo: this.lang.cadastroDeUsuarioDeEmpresa.situacao, width: '20%', orderby: null },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.nomeDeUsuario, item.nome, item.email, item.situacao ? item.situacao.descricao : null];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.nome) {
            showError(this.lang.cadastroDeUsuarioDeEmpresa.mensagens.informeONomeDoUsuario);
            reject();
            return;
         }

         if (!item.nomeDeUsuario) {
            showError(this.lang.cadastroDeUsuarioDeEmpresa.mensagens.informeONomeDeUsuario);
            reject();
            return;
         }

         // if (!item.cpf) {
         //     showError(this.lang.cadastroDeUsuarioDeEmpresa.mensagens.informeOCpfDoUsuario);
         //     reject();
         //     return;
         // }

         if (!item.perfis || (item.perfis && item.perfis.length === 0)) {
            showError(this.lang.cadastroDeUsuarioDeEmpresa.mensagens.informeOPerfilDoUsuario);
            reject();
            return;
         }

         if (!item.cargos) {
            showError(this.lang.cadastroDeUsuarioDeEmpresa.mensagens.informeOCargoDoUsuario);
            reject();
            return;
         }

         this.api
            .get('/publickey')
            .then((publicKey) => {
               let senhaCriptografada = null;
               let novaSenha = null;
               if (item.senha) {
                  let blocks = item.senha.match(new RegExp('.{1,' + 64 + '}', 'g'));
                  for (let i = 0; i < blocks.length; i++) {
                     let buffer = rsaEncrypt(blocks[i], publicKey);
                     senhaCriptografada = senhaCriptografada ? Buffer.concat([senhaCriptografada, buffer]) : buffer;
                  }
                  novaSenha = btoa(senhaCriptografada);
               }

               var input = {
                  nome: item.nome,
                  situacao: item.situacao ? item.situacao.id : 0,
                  perfis: item.perfis,
                  email: item.email,
                  nomeDeUsuario: item.nomeDeUsuario,
                  telefones: item.telefones,
                  cargos: item.cargos,
                  foto: item.foto && item.foto.id ? item.foto : null,
                  senhaAlterada: item.senhaAlterada,
                  novaSenha: novaSenha,
                  enviarNovaSenhaPorEmail: item.enviarNovaSenhaPorEmail,
                  usuarioDeConsulta: item.usuarioDeConsulta,
               };

               if (state.alterando) {
                  input.id = parseInt(item.id);
               }

               resolve(input);
            })
            .catch((e) => {
               console.error(e);
               reject();
            });
      });
   };

   inserirPerfil = () => {
      return new Promise((resolve, reject) => {
         let perfilSelecionado = this.view.state.perfilSelecionado;

         if (!perfilSelecionado) {
            showError(this.lang.cadastroDeUsuarioDeEmpresa.mensagens.selecioneUmPerfilDeUsuario);
            reject();
            return;
         }

         let duplicado =
            this.view.state.itemSelecionado.perfis &&
            this.view.state.itemSelecionado.perfis.some((i) => i.rotina.id === perfilSelecionado.id);

         if (duplicado) {
            showError(this.lang.cadastroDeUsuarioDeEmpresa.mensagens.perfilJaCadastrado);
            reject();
            return;
         }

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.perfis) {
               state.itemSelecionado.perfis = [];
            }
            state.itemSelecionado.perfis.push({
               perfil: perfilSelecionado,
            });
            state.perfilSelecionado = null;
         }).then(resolve);
      });
   };

   inserirCargo = () => {
      return new Promise((resolve, reject) => {
         let cargoSelecionado = this.view.state.cargoSelecionado;

         if (!cargoSelecionado) {
            showError(this.lang.cadastroDeUsuarioDeEmpresa.mensagens.selecioneUmCargo);
            reject();
            return;
         }

         let duplicado =
            this.view.state.itemSelecionado.perfis &&
            this.view.state.itemSelecionado.cargos.some((i) => i.rotina.id === cargoSelecionado.id);

         if (duplicado) {
            showError(this.lang.cadastroDeUsuarioDeEmpresa.mensagens.cargoJaCadastrado);
            reject();
            return;
         }

         updateState(this.view, (state) => {
            if (!state.itemSelecionado.cargos) {
               state.itemSelecionado.cargos = [];
            }
            state.itemSelecionado.cargos.push({
               cargo: cargoSelecionado,
            });
            state.cargoSelecionado = null;
         }).then(resolve);
      });
   };

   inserirTelefone = () => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }
         updateState(this.view, (state) => {
            if (!state.itemSelecionado.telefones) {
               state.itemSelecionado.telefones = [];
            }
            state.itemSelecionado.telefones.push({
               pais: this.view.state.telefoneSelecionado.pais,
               ddd: this.view.state.telefoneSelecionado.ddd,
               numero: this.view.state.telefoneSelecionado.numero,
               temWhatsApp: this.view.state.telefoneSelecionado.temWhatsApp ? true : false,
            });
         }).then(() => resolve());
      });
   };

   alterarTelefone = (index) => {
      return new Promise((resolve, reject) => {
         if (!validarTelefone('+55', this.view.state.telefoneSelecionado, this.lang, reject)) {
            return;
         }

         updateState(this.view, (state) => {
            state.itemSelecionado.telefones[index].pais = this.view.state.telefoneSelecionado.pais;
            state.itemSelecionado.telefones[index].ddd = this.view.state.telefoneSelecionado.ddd;
            state.itemSelecionado.telefones[index].numero = this.view.state.telefoneSelecionado.numero;
            state.itemSelecionado.telefones[index].temWhatsApp = this.view.state.telefoneSelecionado.temWhatsApp;
         }).then(() => resolve());
      });
   };
}
