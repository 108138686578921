import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from './FormularioPadrao';
import Label from '../components/Label';
import TextInput from '../components/TextInput';
import RotinaDoSistemaController from '../controllers/RotinaDoSistemaController';
import { updateState } from '../utils/Functions';

export default class RotinaDoSistemaView extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.controller = new RotinaDoSistemaController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let telaDeCadastroDeRotinaDoSistema = lang.telaDeCadastroDeRotinaDoSistema;

      return (
         <React.Fragment>
            <Row>
               <Col sm={3} md={3} lg={3}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col sm={9} md={9} lg={9}>
                  <FormGroup>
                     <Label>{telaDeCadastroDeRotinaDoSistema.descricao}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.descricao}
                        onChange={(e) => {
                           updateState(this, (state) => (state.itemSelecionado.descricao = e.target.value));
                        }}
                        onInput={(e) => (e.target.value = ('' + e.target.value).toUpperCase())}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <br />
            <br />
         </React.Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeRotinaDoSistema.titulo}
            url={'/rotinadosistema'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id'}
            permissoes={[1031, null, null, null]}
            getFiltro={this.controller.getFiltro}
            filtroExtra={this.props.filtroExtra}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            select={this.props.select}
            itemVazio={{}}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            getFormState={() => {
               return this.state;
            }}
         />
      );
   }
}
