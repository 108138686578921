import React, { Component } from 'react';
import { Modal, ModalManager, Effect } from 'react-dynamic-modal';
import { LayoutParams } from '../config/LayoutParams';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

export default class Messages {
   showInfo = (text) => {
      return new Promise(function (resolve, reject) {
         ModalManager.open(<ModalInfo title='Informação' text={text} onRequestClose={() => resolve()} />);
      });
   };

   showError = (text) => {
      return new Promise(function (resolve, reject) {
         ModalManager.open(<ModalError title='Ops!' text={text} onRequestClose={() => resolve()} />);
      });
   };

   showConfirm = (text, onConfirm, onCancel) => {
      ModalManager.open(
         <ModalConfirm
            title='Confirmação'
            text={text}
            onRequestClose={() => true}
            onConfirm={onConfirm}
            onCancel={onCancel}
         />
      );
   };
}

export const showInfo = (text, title) => {
   return new Promise(function (resolve, reject) {
      ModalManager.open(
         <ModalInfo title={title ? title : 'Informação'} text={text} onRequestClose={() => resolve()} />
      );
   });
};

export const showError = (text, details, stack) => {
   return new Promise(function (resolve, reject) {
      ModalManager.open(
         <ModalError title='Ops!' text={text} details={details} stack={stack} onRequestClose={() => resolve()} />
      );
   });
};

export const showConfirm = (text, onConfirm, onCancel, confirmText, cancelText) => {
   ModalManager.open(
      <ModalConfirm
         title='Confirmação'
         text={text}
         onRequestClose={() => true}
         onConfirm={onConfirm}
         onCancel={onCancel}
         confirmText={confirmText}
         cancelText={cancelText}
      />
   );
};

class ModalInfo extends Component {
   render() {
      const { title, text, onRequestClose } = this.props;
      return (
         <Modal
            onRequestClose={onRequestClose}
            effect={Effect.SlideFromBottom}
            style={{ content: { minWidth: '300px', maxWidth: '500px' } }}
         >
            <div className='modal-content' size='md' style={{ border: 0 }}>
               <div className='modal-header' style={{ backgroundColor: LayoutParams.colors.corDoTemaPrincipal }}>
                  <div className='modal-title h4' style={{ color: LayoutParams.colors.corSecundaria }}>
                     {title}
                  </div>
               </div>
               <div
                  className='modal-body'
                  style={{ display: 'flex', overflow: 'auto', maxHeight: 400, whiteSpace: 'break-spaces' }}
               >
                  {text}
               </div>
               <div className='modal-footer'>
                  <Button
                     text='OK'
                     style={{ width: '130px' }}
                     onClick={() => {
                        ModalManager.close();
                        onRequestClose();
                     }}
                     ref={(c) => {
                        if (c) c.focus();
                     }}
                  />
               </div>
            </div>
         </Modal>
      );
   }
}

class ModalError extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showDetails: false,
      };
   }

   render() {
      const { title, text, details, stack, onRequestClose } = this.props;
      return (
         <Modal
            onRequestClose={onRequestClose}
            effect={Effect.SlideFromBottom}
            style={{ content: { minWidth: '300px', maxWidth: '500px' } }}
         >
            <div className='modal-content' size='md' style={{ border: 0 }}>
               <div className='modal-header' style={{ backgroundColor: LayoutParams.colors.corDoTemaPrincipal }}>
                  <div className='modal-title h4' style={{ color: LayoutParams.colors.corSecundaria }}>
                     {title}
                  </div>
               </div>
               <div
                  className='modal-body'
                  style={{
                     display: 'flex',
                     flexDirection: 'column',
                     overflow: 'auto',
                     maxHeight: 400,
                     whiteSpace: 'break-spaces',
                  }}
               >
                  <div>{text}</div>
                  {(details || stack) && (
                     <div>
                        <div style={{ display: 'flex' }}>
                           <div
                              style={{
                                 margin: 'auto',
                                 color: 'gray',
                                 fontSize: 20,
                                 cursor: 'pointer',
                                 width: 26,
                                 height: 26,
                                 borderRadius: 13,
                                 textAlign: 'center',
                              }}
                              onClick={() => {
                                 this.setState({
                                    showDetails: !this.state.showDetails,
                                 });
                              }}
                           >
                              <FontAwesomeIcon
                                 icon={this.state.showDetails ? faAngleUp : faAngleDown}
                                 style={{ marginTop: 3 }}
                              />
                           </div>
                        </div>
                        {this.state.showDetails && (
                           <div>
                              <div>{details}</div>
                              {stack && (
                                 <div style={{ fontSize: 10, border: '1px solid gray', borderRadius: 5 }}>
                                    <span> {stack}</span>
                                 </div>
                              )}
                           </div>
                        )}
                     </div>
                  )}
               </div>
               <div className='modal-footer'>
                  <Button
                     text='OK'
                     style={{ width: '130px' }}
                     onClick={() => {
                        ModalManager.close();
                        onRequestClose();
                     }}
                     ref={(c) => {
                        if (c) c.focus();
                     }}
                  />
               </div>
            </div>
         </Modal>
      );
   }
}

class ModalConfirm extends Component {
   render() {
      const { title, text, onRequestClose } = this.props;
      return (
         <Modal
            onRequestClose={onRequestClose}
            effect={Effect.SlideFromBottom}
            style={{ content: { minWidth: '300px', maxWidth: '500px' } }}
         >
            <div className='modal-content' size='md' style={{ border: 0 }}>
               <div className='modal-header' style={{ backgroundColor: LayoutParams.colors.corDoTemaPrincipal }}>
                  <div className='modal-title h4' style={{ color: LayoutParams.colors.corSecundaria }}>
                     {title}
                  </div>
               </div>
               <div
                  className='modal-body'
                  style={{ display: 'flex', overflow: 'auto', maxHeight: 400, whiteSpace: 'break-spaces' }}
               >
                  {text}
               </div>
               <div className='modal-footer'>
                  <Button
                     text={this.props.cancelText ? this.props.cancelText : 'Cancelar'}
                     onClick={() => {
                        if (this.props.onCancel) {
                           this.props.onCancel();
                        }
                        ModalManager.close();
                     }}
                     style={{ width: '130px' }}
                  />
                  <Button
                     text={this.props.confirmText ? this.props.confirmText : 'Confirmar'}
                     onClick={() => {
                        if (this.props.onConfirm !== undefined) {
                           this.props.onConfirm();
                        }
                        ModalManager.close();
                     }}
                     style={{ width: '130px' }}
                  />
               </div>
            </div>
         </Modal>
      );
   }
}
