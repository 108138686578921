import { showError } from '../../components/Messages';
import { BaseController } from '../BaseController';

export default class TermoDeUsoController extends BaseController {
    constructor(props) {
        super(props);
        this.name = 'TermoDeUsoController';
    }

    getTitulosDaTabela = () => {
        return [
            { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
            { titulo: this.lang.telaDecadastroDeTermoDeUso.nome, width: '100%', orderby: 'nome' },
        ];
    };

    getDadosDaTabela = (item) => {
        return [item.id, item.nome ];
    };

    getObjetoDeDados = () => {
        return new Promise((resolve, reject) => {
            let state = this.view.state;
            let item = state.itemSelecionado;

            if (!item.nome) {
                showError(this.lang.telaDecadastroDeTermoDeUso.mensagens.informeOTermo);
                reject();
                return;
            }           
            var input = {
                nome : item.nome,
                termo : item.termo
            };

            if (state.alterando) {
                input.id = parseInt(item.id);
            }

            resolve(input);
        });
    };
}
