import React from 'react';
import TextInput from '../components/TextInput';
import CepInput from '../components/CepInput';
import CheckBox from '../components/CheckBox';
import CnpjInput from '../components/CnpjInput';
import CpfInput from '../components/CpfInput';
import DateInput from '../components/DateInput';
import DecimalInput from '../components/DecimalInput';
import IntegerInput from '../components/IntegerInput';
import RadioInput from '../components/RadioInput';
import Select from '../components/Select';
import TelefoneCelularInput from '../components/TelefoneCelularInput';
import TelefoneFixoInput from '../components/TelefoneFixoInput';
import TimeInput from '../components/TimeInput';
import Panel from '../components/Panel';
import Label from '../components/Label';
import Button from '../components/Button';
import SubCadastro from '../components/SubCadastro';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import { updateState, buildQueryString } from '../utils/Functions';
import TextArea from '../components/TextArea';
import Api from '../utils/Api';
import Avatar from '../components/Avatar';
import SessionManager from '../models/SessionManager';
import Image from '../components/Image';
import Document from '../components/Document';
import { getLang } from '../models/Lang.js';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

export default class DemonstracaoDeComponentes extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         itens: [
            { id: 1, descricao: 'item 1' },
            { id: 2, descricao: 'item 2' },
            { id: 3, descricao: 'item 3' },
         ],
         image: null,
         file: { id: 1, name: 'Nome Arquivo' },
      };
      this.sessionManager = new SessionManager();
      this.lang = getLang();
   }

   render() {
      return (
         <Panel>
            <Button
               text='Testar Zoom'
               onClick={() => {
                  if (this.transformWrapper) {
                     console.log(this.transformWrapper);
                  }
               }}
            />

            <TransformWrapper
               initialScale={2}
               ref={(c) => (this.transformWrapper = c)}
               initialPositionX={60}
               initialPositionY={60}
               centerZoomedOut={false}
               //centerOnInit={true}
            >
               <TransformComponent
                  wrapperStyle={{
                     height: 500,
                     width: 300,
                     border: '2px solid red',
                     backgroundColor: '#9996',
                  }}

               >
                  <div style={{ backgroundColor: 'yellow', display: 'flex' , marginTop : -100}}>
                     <div style={{ backgroundColor: 'yellow', display: 'flex', flexDirection: 'column' }}>
                        <div
                           style={{
                              height: 50,
                              width: 50,
                              border: '2px solid red',
                              backgroundColor: '#9996',
                              borderRadius: 50,
                           }}
                        >
                           1
                        </div>
                        <div
                           style={{
                              height: 50,
                              width: 50,
                              border: '2px solid red',
                              backgroundColor: 'green',
                              borderRadius: 50,
                           }}
                        >
                           2
                        </div>
                     </div>
                     <div style={{ backgroundColor: 'yellow', display: 'flex', flexDirection: 'column' }}>
                        <div
                           style={{
                              height: 50,
                              width: 50,
                              border: '2px solid red',
                              backgroundColor: 'blue',
                              borderRadius: 50,
                           }}
                        >
                           3
                        </div>
                        <div
                           style={{
                              height: 50,
                              width: 50,
                              border: '2px solid red',
                              backgroundColor: 'orange',
                              borderRadius: 50,
                           }}
                        >
                           4
                        </div>
                     </div>
                  </div>
               </TransformComponent>
            </TransformWrapper>

            <Button
               text='Testar'
               onClick={() => {
                  try {
                     buildQueryString(1, null, null, { searchable: 'RAMON DA SIL', idade: 56 });
                  } catch (e) {
                     console.error(e);
                  }
               }}
            />
            <Label>Document</Label>
            <Document
               defaultValue={this.state.file}
               api={new Api()}
               lang={this.lang}
               onChange={(file) => {
                  this.setState({ file: file });
               }}
            />
            <br />
            <Container>
               <SubCadastro
                  titulo='Sub cadastro'
                  exibirTitulos={true}
                  lang={this.lang}
                  itens={this.state.itens}
                  novo={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.itemSelecionado = {};
                        }).then(() => resolve('novo item criado...'));
                     });
                  }}
                  inserir={() => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           state.itens.push({
                              descricao: state.itemSelecionado.descricao,
                           });
                        }).then(() => resolve('item inserido...'));
                     });
                  }}
                  selecionarParaAlteracao={(index) => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           let itemSelecionado = state.itens[index];
                           state.itemSelecionado = itemSelecionado;
                        }).then(() => resolve('inclusão/alteração iniciada...'));
                     });
                  }}
                  alterar={(index) => {
                     return new Promise((resolve) => {
                        updateState(this, (state) => {
                           console.log(state.itemSelecionado);
                           state.itens[index].descricao = state.itemSelecionado.descricao;
                        }).then(() => resolve('item alterado...'));
                     });
                  }}
                  cancelar={() => {
                     return new Promise((resolve) => {
                        resolve('inclusão/alteração cancelada...');
                     });
                  }}
                  excluir={(index) => {
                     updateState(this, (state) => {
                        state.itens.splice(index, 1);
                     });
                  }}
                  colunas={() => {
                     return [
                        { titulo: 'Código', width: '20%', className: null },
                        { titulo: 'Descrição', width: '80%', className: null },
                     ];
                  }}
                  linha={(item) => {
                     return [item.id, item.descricao];
                  }}
                  formulario={() => {
                     return (
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{'Descrição'}</Label>
                                 <TextInput
                                    name='TextInput'
                                    defaultValue={this.state.itemSelecionado.descricao}
                                    onChange={(e) =>
                                       updateState(this, (state) => (state.itemSelecionado.descricao = e.target.value))
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                     );
                  }}
               />
            </Container>

            <br />
            <br />
            <Label>Avatar</Label>
            <Avatar
               image={this.state.image}
               api={new Api()}
               lang={this.lang}
               onChange={(image) => {
                  this.setState({ image: image });
               }}
            />
            <br />
            <Label>Image</Label>
            <Image
               image={this.state.image}
               api={new Api()}
               lang={this.lang}
               onChange={(image) => {
                  this.setState({ image: image });
               }}
            />
            <br />

            <Label>TextInput</Label>
            <TextInput name='TextInput' />
            <br />
            <Label>TextArea</Label>
            <TextArea name='TextArea' rows={4} />
            <br />
            <Label>CepInput</Label>
            <CepInput />
            <br />
            <CheckBox label='CheckBox' name='CheckBox' />
            <br />
            <Label>CnpjInput</Label>
            <CnpjInput />
            <br />
            <Label>CpfInput</Label>
            <CpfInput />
            <br />
            <Label>DateInput</Label>
            <DateInput lang={this.lang}/>
            <br />
            <Label>DecimalInput</Label>
            <DecimalInput />
            <br />
            <Label>IntegerInput</Label>
            <IntegerInput />
            <br />
            <RadioInput label='RadioInput' name='CheckBox' />
            <br />
            <Label>Select</Label>
            <Select
               options={[
                  { id: 1, nome: 'Fernando Silva' },
                  { id: 2, nome: 'Adriano Fonseca' },
                  { id: 3, nome: 'Ana Ribeiro' },
               ]}
               getKeyValue={(i) => i.id}
               getDescription={(i) => i.nome}
               onSelect={(i) => {
                  console.log(i);
               }}
            />
            <br />
            <Label>TelefoneCelularInput</Label>
            <TelefoneCelularInput />
            <br />
            <Label>TelefoneFixoInput</Label>
            <TelefoneFixoInput />
            <br />
            <Label>TimeInput</Label>
            <TimeInput />
            <br />
            <Button text='Button' inProgressText='Working...' onClickAsync={this.onClickAsync} />
         </Panel>
      );
   }

   onClickAsync() {
      var result = new Promise((resolve, reject) => {
         console.log('OnClick started...');
         setTimeout(() => {
            console.log('OnClick finished...');
            resolve();
         }, 3000);
      });
      return result;
   }
}
