import React from 'react';
import { Button as BoottrapButton } from 'react-bootstrap';
import { LayoutParams } from '../config/LayoutParams';
import '../contents/css/button-input.css';

export default class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text,
            disabled: false,
        };
    }

    focus = () => {
        if (this.button) {
            this.button.focus();
        }
    };

    render() {
        let defaultStyle = {
            backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
            borderBlockColor: LayoutParams.colors.corSecundaria,
            color: LayoutParams.colors.corSecundaria,
            borderColor: LayoutParams.colors.corSecundaria,
            fontSize: 16,
            height: 36,
            textAlign: 'center',
            margin: 0,
            padding: '0 4px 0 4px',
            overFlow: 'hidden'
        };

        return (
            <BoottrapButton
                className={'button-input' + (this.props.className ? ' ' + this.props.className : '')}
                style={{ ...defaultStyle, ...this.props.style }}
                title={this.props.title}
                name={this.props.name}
                id={this.props.id}
                key={this.props.key}
                ref={(c) => {
                    this.button = c;
                }}
                disabled={this.state.disabled || this.props.disabled}
                block
                type={this.props.submit ? 'submit' : 'button'}
                onClick={(e) => {
                    if (this.props.onClick) {
                        this.setState({ text: this.props.inProgressText, disabled: true }, () => {
                            this.props.onClick(e);
                            this.setState({ text: this.props.text, disabled: false });
                        });
                    } else if (this.props.onClickAsync) {
                        this.setState({ text: this.props.inProgressText, disabled: true }, () => {
                            this.props
                                .onClickAsync(e)
                                .catch(() => {
                                    this.setState({ text: this.props.text, disabled: false });
                                })
                                .finally(() => {
                                    this.setState({ text: this.props.text, disabled: false });
                                });
                        });
                    }
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        paddingLeft: 3,
                        paddingRight: 3,
                    }}
                >
                    <div
                        style={{
                            display: 'table-cell',
                            width: this.props.icon && this.state.text ? 'fit-content' : '',
                        }}
                    >
                        {this.props.icon}
                    </div>
                    {this.state.text && (
                        <div
                            style={{
                                display: 'table-cell',
                                width: this.state.text ? 'fit-content' : null,
                                textAlign: 'left',
                                paddingLeft: 5,
                                justifyContent: 'center',
                            }}
                        >
                            {this.state.text}
                        </div>
                    )}
                </div>
            </BoottrapButton>
        );
    }
}
