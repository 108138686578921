import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import SubCadastro from '../../components/SubCadastro';
import Avatar from '../../components/Avatar';
import { updateState } from '../../utils/Functions';
import DadosDeUsuarioDeEmpresaController from '../../controllers/Empresa/DadosDeUsuarioDeEmpresaController';
import CheckBox from '../../components/CheckBox';
import { IconeWhatsApp } from '../../components/IconeWhatsApp';
import PasswordInput from '../../components/PasswordInput';
import { faKey } from '@fortawesome/free-solid-svg-icons';

const url = '/dadosdeusuariodeempresa';

export default class DadosDeUsuarioDeEmpresaView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         foto: null,
         mostrarEndereco: true,
         url: url,
      };
      this.controller = new DadosDeUsuarioDeEmpresaController(this);
      this.lang = this.props.lang;
   }

   renderizarFormulario = () => {
      return (
         <React.Fragment>
            <Row>
               <Col>
                  <Row>
                     <Col sm={3} md={3} lg={3}>
                        <Label>{this.lang.codigo}</Label>
                        <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                     </Col>

                     <Col>
                        <FormGroup>
                           <Label>{this.lang.cadastroDeUsuarioDeEmpresa.nome}</Label>
                           <TextInput defaultValue={this.state.itemSelecionado.nome} readOnly={true} />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row>
                     <Col>
                        <FormGroup>
                           <Label>{this.lang.cadastroDeUsuarioDeEmpresa.login}</Label>
                           <TextInput
                              name={'nomeDeUsuario'}
                              defaultValue={this.state.itemSelecionado.nomeDeUsuario}
                              readOnly={true}
                           />
                        </FormGroup>
                     </Col>
                     <Col sm={3} md={3} lg={3}>
                        <FormGroup>
                           <Label>{this.props.lang.cadastroDeUsuarioDeEmpresa.senha}</Label>
                           {!this.state.esconderSenha && (
                              <React.Fragment>
                                 <PasswordInput
                                    defaultValue={this.state.itemSelecionado.senha ? '******' : null}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.itemSelecionado.senha = e.target.value;
                                          state.itemSelecionado.senhaAlterada = true;
                                       });
                                    }}
                                    appendIcon={faKey}
                                 />
                              </React.Fragment>
                           )}
                        </FormGroup>
                     </Col>
                  </Row>
               </Col>

               <Col style={{ textAlign: 'right', maxWidth: 150, marginLeft: -20 }}>
                  <Avatar
                     lang={this.props.lang}
                     api={this.props.api}
                     image={this.state.itemSelecionado.foto}
                     readOnly={true}
                  />
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{this.lang.cadastroDeUsuarioDeEmpresa.email}</Label>
                     <TextInput defaultValue={this.state.itemSelecionado.email} readOnly={true} />
                  </FormGroup>
               </Col>
            </Row>
            <br />

            {this.telefones()}

            <br />
         </React.Fragment>
      );
   };

   telefones = () => {
      return (
         <SubCadastro
            titulo={this.lang.cadastroDeUsuarioDeEmpresa.telefones}
            exibirTitulos={false}
            itens={this.state.itemSelecionado.telefones}
            lang={this.lang}
            novo={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = {
                        pais: null,
                        ddd: null,
                        numero: null,
                        temWhatsApp: false,
                     };
                  }).then(() => resolve());
               });
            }}
            inserir={this.controller.inserirTelefone}
            selecionarParaAlteracao={(index) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = JSON.parse(JSON.stringify(state.itemSelecionado.telefones[index]));
                  }).then(() => resolve());
               });
            }}
            alterar={this.controller.alterarTelefone}
            cancelar={() => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.telefoneSelecionado = null;
                  }).then(() => resolve());
               });
            }}
            excluir={(index) => {
               updateState(this, (state) => {
                  state.itemSelecionado.telefones.splice(index, 1);
               });
            }}
            colunas={() => {
               return [{ titulo: '', width: '100%', className: null }];
            }}
            linha={(item) => {
               return [
                  <div style={{ display: 'flex' }}>
                     <div>{item.pais + ' ' + item.ddd + ' ' + item.numero + ' '} </div>
                     <div>{item.temWhatsApp ? <IconeWhatsApp key={'iconeWhatsApp'} /> : null}</div>
                  </div>,
               ];
            }}
            formulario={() => {
               return (
                  <div>
                     <Row>
                        <Col>
                           <div style={{ display: 'flex' }}>
                              <FormGroup>
                                 <Label>{this.lang.telefone.pais}</Label>
                                 <TextInput
                                    name='pais'
                                    defaultValue={this.state.telefoneSelecionado.pais}
                                    style={{ width: '60px' }}
                                    maxLength={3}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.pais = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup>
                                 <Label>{this.lang.telefone.ddd}</Label>
                                 <TextInput
                                    name='ddd'
                                    defaultValue={this.state.telefoneSelecionado.ddd}
                                    style={{ width: '60px' }}
                                    maxLength={2}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.ddd = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup>
                                 <Label>{this.lang.telefone.numero}</Label>
                                 <TextInput
                                    name='telefone'
                                    defaultValue={this.state.telefoneSelecionado.numero}
                                    maxLength={9}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.numero = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup
                                 controlId='chktemWhatsApp'
                                 style={{
                                    margin: 'auto',
                                    paddingLeft: '5px',
                                    fontWeight: 'normal',
                                    width: '100%',
                                 }}
                              >
                                 <CheckBox
                                    defaultChecked={this.state.telefoneSelecionado.temWhatsApp ? true : false}
                                    name='WhatsApp'
                                    label='WhatsApp'
                                    onChange={(checked) => {
                                       updateState(this, (state) => {
                                          state.telefoneSelecionado.temWhatsApp = checked;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </div>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.lang.cadastroDeUsuarioDeEmpresa.dadosDaConta}
            url={url}
            api={this.props.api}
            lang={this.props.lang}
            apenasAlterar={true}
            id={this.props.id}
            ordenacaoPadrao={'id'}
            permissoes={[1361, null, 1363, null]}
            getFiltro={this.controller.getFiltro}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            ref={(c) => (this.form = c)}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               telefones: [],
               situacao: { id: 1 },
            }}
         />
      );
   }
}
